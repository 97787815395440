import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Row,
  Col,
  Typography,
  Statistic,
  Space,
  Badge,
  Table,
  List,
  Spin,
  Alert,
} from "antd";
import {
  UsergroupAddOutlined,
  BankOutlined,
  DollarOutlined,
  AlertOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  Inject,
  AccumulationLegend,
  PieSeries,
  AccumulationTooltip,
  AccumulationDataLabel,
} from "@syncfusion/ej2-react-charts";
import RevenueComparison from "./RevenueComparison";
import { BASE_URL } from "../../Constants";
import MonthlyRevenueBarGraph from "./MonthlyRevenueBarGraph";

const { Title, Text } = Typography;

// Maintain original sample data as a fallback
const originalSampleData = {
  companies: {
    total: 800,
    activeTrials: 45,
    expiringTrials: 12,
    industries: [
      { name: "Technology", count: 250, employees: 2800 },
      { name: "Finance", count: 180, employees: 2200 },
      { name: "Healthcare", count: 150, employees: 1900 },
      { name: "Manufacturing", count: 120, employees: 2500 },
      { name: "Others", count: 100, employees: 1600 },
    ],
    monthlyGrowth: [
      { month: "Jan", companies: 750, employees: 10200 },
      { month: "Feb", companies: 765, employees: 10500 },
      { month: "Mar", companies: 782, employees: 10800 },
      { month: "Apr", companies: 800, employees: 11000 },
    ],
  },
  revenue: {
    current: 980000,
    previous: 850000,
    distribution: [
      { category: "Enterprise Plans", amount: 450000 },
      { category: "Business Plans", amount: 320000 },
      { category: "Basic Plans", amount: 210000 },
    ],
    monthlyData: [
      { month: "Jan", current: 75000, previous: 65000 },
      { month: "Feb", current: 82000, previous: 68000 },
      { month: "Mar", current: 88000, previous: 72000 },
      { month: "Apr", current: 95000, previous: 75000 },
    ],
  },
  subscriptions: {
    active: [
      {
        plan: "Enterprise",
        count: 120,
        expiringSoon: 15,
        companies: [
          { name: "Tech Corp A", status: "Active", expiresIn: 45 },
          { name: "Finance Ltd B", status: "Expiring Soon", expiresIn: 15 },
          { name: "Health Corp C", status: "Active", expiresIn: 90 },
        ],
      },
      {
        plan: "Business",
        count: 280,
        expiringSoon: 42,
        companies: [
          { name: "Small Tech D", status: "Expiring Soon", expiresIn: 10 },
          { name: "Med Corp E", status: "Active", expiresIn: 60 },
          { name: "Service Ltd F", status: "Active", expiresIn: 75 },
        ],
      },
      {
        plan: "Basic",
        count: 400,
        expiringSoon: 68,
        companies: [
          { name: "Startup G", status: "Expiring Soon", expiresIn: 5 },
          { name: "New Biz H", status: "Active", expiresIn: 85 },
          { name: "Small Shop I", status: "Active", expiresIn: 95 },
        ],
      },
    ],
  },
};

const SuperadminDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/Dashboard`);
        setDashboardData(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const legendSettings = {
    visible: true,
    position: "Bottom",
    textStyle: { size: "14px" },
  };

  // Transform API data for subscriptions table
  const transformSubscriptionsData = (companyDetails) => {
    const subscriptionStatuses = {};
    companyDetails.forEach((company) => {
      const status = company.subscriptionStatus || "Unspecified";
      subscriptionStatuses[status] = (subscriptionStatuses[status] || 0) + 1;
    });

    return Object.entries(subscriptionStatuses).map(([status, count]) => ({
      plan: status,
      count,
      expiringSoon: 0, // API doesn't provide expiring soon count
      companies: companyDetails
        .filter((c) => (c.subscriptionStatus || "Unspecified") === status)
        .map((company) => ({
          name: company.name,
          status: company.subscriptionStatus ? "Active" : "Expiring Soon",
          expiresIn: company.subscriptionEndDate
            ? Math.ceil(
                (new Date(company.subscriptionEndDate) - new Date()) /
                  (1000 * 60 * 60 * 24)
              )
            : 0,
        })),
    }));
  };

  // Prepare revenue distribution data
  const revenueDistribution = dashboardData?.revenueDistribution
    ? Object.entries(dashboardData.revenueDistribution).map(
        ([planName, amount]) => ({
          category: planName,
          amount: amount,
        })
      )
    : originalSampleData.revenue.distribution;

  // Render loading state
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <Alert
        message="Error Loading Dashboard"
        description="Unable to fetch dashboard data. Showing sample data."
        type="error"
      />
    );
  }

  // Enhanced subscription table columns
  const subscriptionColumns = [
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
      render: (text) => <Text strong>{text}</Text>,
    },
    {
      title: "Active Companies",
      dataIndex: "count",
      key: "count",
      render: (text) => (
        <Space>
          <CheckCircleOutlined style={{ color: "#52c41a" }} />
          {text}
        </Space>
      ),
    },
    {
      title: "Expiring Soon",
      dataIndex: "expiringSoon",
      key: "expiringSoon",
      render: (text) => (
        <Space>
          <ClockCircleOutlined style={{ color: "#faad14" }} />
          <Badge
            count={text}
            style={{
              backgroundColor: text > 20 ? "#f5222d" : "#faad14",
            }}
          />
        </Space>
      ),
    },
    {
      title: "Companies",
      dataIndex: "companies",
      key: "companies",
      render: (companies) => (
        <List
          size="small"
          dataSource={companies}
          renderItem={(item) => (
            <List.Item>
              <Space>
                <Text>{item.name}</Text>
                <Badge
                  status={item.status === "Active" ? "success" : "warning"}
                  text={`${item.expiresIn} days`}
                />
              </Space>
            </List.Item>
          )}
        />
      ),
    },
  ];

  return (
    <div
      style={{
        padding: "24px",
        background: "#f2f0f5",
        borderRadius: "15px",
        minHeight: "100vh",
      }}
    >
      {/* Stats Cards */}
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} lg={6}>
          <Card>
            <Statistic
              title={
                <Space>
                  <BankOutlined />
                  <span>Total Companies</span>
                </Space>
              }
              value={
                dashboardData?.totalCompanies ||
                originalSampleData.companies.total
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card>
            <Statistic
              title={
                <Space>
                  <UsergroupAddOutlined />
                  <span>Total Employees</span>
                </Space>
              }
              value={
                dashboardData?.globalStatistics
                  ?.totalEmployeesAcrossCompanies ||
                originalSampleData.companies.monthlyGrowth[3].employees
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card>
            <Statistic
              title={
                <Space>
                  <DollarOutlined />
                  <span>Revenue</span>
                </Space>
              }
              value={
                `SAR ${dashboardData?.globalStatistics?.totalSubscriptionRevenue}` ||
                originalSampleData.revenue.current
              }

              // precision={2}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card>
            <Statistic
              title={
                <Space>
                  <AlertOutlined />
                  <span>Active Companies</span>
                </Space>
              }
              value={
                dashboardData?.activeCompanies ||
                originalSampleData.companies.activeTrials
              }
              suffix={
                <Badge
                  count={`${
                    dashboardData?.inactiveCompanies ||
                    originalSampleData.companies.expiringTrials
                  } Inactive`}
                  style={{ backgroundColor: "#faad14" }}
                />
              }
            />
          </Card>
        </Col>
      </Row>

      {/* Charts */}
      <Row gutter={[24, 24]} style={{ marginTop: "24px" }}>
        {/* <Col xs={24} lg={12}>
          <RevenueComparison data={dashboardData || originalSampleData} />
        </Col> */}
        <Col xs={24} lg={12}>
          <MonthlyRevenueBarGraph
            monthlyRevenueData={dashboardData?.monthlyRevenue || {}}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Card title="Revenue Distribution">
            <AccumulationChartComponent
              id="revenue-donut"
              tooltip={{ enable: true }}
              legendSettings={legendSettings}
              height="350px"
            >
              <Inject
                services={[
                  AccumulationLegend,
                  PieSeries,
                  AccumulationTooltip,
                  AccumulationDataLabel,
                ]}
              />
              <AccumulationSeriesCollectionDirective>
                <AccumulationSeriesDirective
                  dataSource={revenueDistribution}
                  xName="category"
                  yName="amount"
                  innerRadius="40%"
                  dataLabel={{
                    visible: true,
                    position: "Outside",
                    name: "category",
                  }}
                />
              </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>
          </Card>
        </Col>
      </Row>

      {/* Subscription Status */}
      <Row style={{ marginTop: "24px" }}>
        <Col xs={24}>
          <Card
            title={
              <Space>
                <Text strong>Subscription Status</Text>
                <Badge
                  count={dashboardData?.subscriptionBreakdown?.reduce(
                    (acc, curr) =>
                      acc + (curr.status !== "Active" ? curr.count : 0),
                    0
                  )}
                  style={{ backgroundColor: "#faad14" }}
                />
              </Space>
            }
          >
            <Table
              dataSource={transformSubscriptionsData(
                dashboardData?.companyDetails ||
                  originalSampleData.subscriptions.active
              )}
              columns={subscriptionColumns}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => (
                  <List
                    size="small"
                    header={<Text strong>{record.plan} Plan Details</Text>}
                    bordered
                    dataSource={record.companies}
                    renderItem={(item) => (
                      <List.Item>
                        <Space>
                          {item.status === "Active" ? (
                            <CheckCircleOutlined style={{ color: "#52c41a" }} />
                          ) : (
                            <ClockCircleOutlined style={{ color: "#faad14" }} />
                          )}
                          <Text>{item.name}</Text>
                          <Badge
                            status={
                              item.status === "Active" ? "success" : "warning"
                            }
                            text={`Expires in ${item.expiresIn} days`}
                          />
                        </Space>
                      </List.Item>
                    )}
                  />
                ),
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SuperadminDashboard;
