import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import AppContext from "../../AppContext";
import {
  Card,
  Button,
  Tabs,
  Alert,
  Space,
  Input,
  Typography,
  Modal,
  Radio,
} from "antd";
import {
  SaveOutlined,
  UndoOutlined,
  EyeOutlined,
  EditOutlined,
  TranslationOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
const { TextArea } = Input;
const { Title, Text } = Typography;

const EmailTemplateEditor = () => {
  const [templates, setTemplates] = useState({
    en: "",
    ar: "",
  });
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [preview, setPreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { profile } = useContext(AppContext);
  const { id } = useParams();
  // Sample employee data for preview
  const sampleData = {
    employee: {
      FirstName: "John",
      FirstNameAr: "جون",
      Id: "123",
      EmployeeID: "EMP123",
      DepartmentName: "Engineering",
      DepartmentNameAr: "الهندسة",
      OfficeName: "New York Office",
      OfficeNameAr: "مكتب نيويورك",
      Role: "Senior Developer",
      RoleAr: "مطور أول",
      CompanyName: "Tech Corp",
      CompanyNameAr: "تك كورب",
      DigitalID: {
        QRCode: "/api/placeholder/180/180",
      },
    },
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL}/Company/email-template/${id}`
      );

      setTemplates({
        en: response.data.emailTemplate || getDefaultTemplate("en"),
        ar: response.data.arabicEmailTemplate || getDefaultTemplate("ar"),
      });
    } catch (err) {
      setError(err.response?.data?.message || "Failed to load templates");
      setTemplates({
        en: getDefaultTemplate("en"),
        ar: getDefaultTemplate("ar"),
      });
    } finally {
      setLoading(false);
    }
  };

  const saveTemplates = async () => {
    try {
      setLoading(true);
      const templateData = {
        id: profile.companyId,
        emailTemplate: templates.en,
        arabicEmailTemplate: templates.ar,
      };

      const response = await axios.put(
        `${BASE_URL}/Company/email-template`,
        templateData
      );

      setSuccess("Templates saved successfully");
      setTimeout(() => setSuccess(""), 3000);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to save templates");
      setTimeout(() => setError(""), 3000);
    } finally {
      setLoading(false);
    }
  };

  const generatePreview = () => {
    const template = templates[currentLanguage];
    let previewHtml = template;

    const langSuffix = currentLanguage === "ar" ? "Ar" : "";

    // Replace all occurrences of variables with sample data
    Object.entries(sampleData.employee).forEach(([key, value]) => {
      if (typeof value === "object") {
        Object.entries(value).forEach(([subKey, subValue]) => {
          const regex = new RegExp(`{employee\\.${key}\\.${subKey}}`, "g");
          previewHtml = previewHtml.replace(regex, subValue);
        });
      } else {
        const regex = new RegExp(`{employee\\.${key}}`, "g");
        previewHtml = previewHtml.replace(regex, value);
      }
    });

    setPreview(previewHtml);
  };

  const resetToDefault = () => {
    Modal.confirm({
      title: "Reset Template",
      content: `Are you sure you want to reset the ${
        currentLanguage === "en" ? "English" : "Arabic"
      } template to default? This will discard all your changes.`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setTemplates((prev) => ({
          ...prev,
          [currentLanguage]: getDefaultTemplate(currentLanguage),
        }));
      },
    });
  };

  const handleTemplateChange = (e) => {
    setTemplates((prev) => ({
      ...prev,
      [currentLanguage]: e.target.value,
    }));
  };

  const tabItems = [
    {
      key: "edit",
      label: (
        <span>
          <EditOutlined />
          Edit Template
        </span>
      ),
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Radio.Group
              value={currentLanguage}
              onChange={(e) => setCurrentLanguage(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value="en">English Template</Radio.Button>
              <Radio.Button value="ar">Arabic Template</Radio.Button>
            </Radio.Group>

            <Card size="small" className="variables-reference">
              <Text strong>Available Variables:</Text>
              <div style={{ marginTop: 8 }}>
                <Text code>
                  {currentLanguage === "en"
                    ? "{employee.FirstName}, {employee.Id}, {employee.EmployeeID}, {employee.DepartmentName}, {employee.Role}, {employee.CompanyName}, {employee.DigitalID.QRCode}"
                    : "{employee.FirstName}, {employee.Id}, {employee.EmployeeID}, {employee.DepartmentName}, {employee.Role}, {employee.CompanyName}, {employee.DigitalID.QRCode}"}
                </Text>
              </div>
            </Card>

            <TextArea
              value={templates[currentLanguage]}
              onChange={handleTemplateChange}
              style={{
                height: 600,
                fontFamily: "monospace",
                fontSize: "14px",
                direction: currentLanguage === "ar" ? "rtl" : "ltr",
              }}
              placeholder="Enter your HTML template here..."
            />
          </Space>
        </div>
      ),
    },
    {
      key: "preview",
      label: (
        <span>
          <EyeOutlined />
          Preview
        </span>
      ),
      children: (
        <div
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
            padding: "16px",
            direction: currentLanguage === "ar" ? "rtl" : "ltr",
          }}
          dangerouslySetInnerHTML={{ __html: preview }}
        />
      ),
    },
  ];

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Title level={4} style={{ margin: 0 }}>
          Email Template Editor
        </Title>
        <Space>
          <Button icon={<UndoOutlined />} onClick={resetToDefault}>
            Reset to Default
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={saveTemplates}
            loading={loading}
          >
            Save Templates
          </Button>
        </Space>
      </div>

      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
          closable
          onClose={() => setError("")}
        />
      )}

      {success && (
        <Alert
          message={success}
          type="success"
          showIcon
          style={{ marginBottom: 16 }}
          closable
          onClose={() => setSuccess("")}
        />
      )}

      <Tabs
        items={tabItems}
        onChange={(key) => {
          if (key === "preview") {
            generatePreview();
          }
        }}
      />
    </Card>
  );
};

const getDefaultTemplate = (language) => {
  if (language === "ar") {
    return `<!DOCTYPE html>
<html dir="rtl" lang="ar">
<head>
    <meta charset='UTF-8'>
    <meta name='viewport' content='width=device-width, initial-scale=1.0'>
</head>
<body style='margin: 0; padding: 0; font-family: Arial, sans-serif; line-height: 1.6; color: #333333; background-color: #f4f4f4; direction: rtl;'>
    <div style='max-width: 600px; margin: 0 auto; background-color: #ffffff; padding: 40px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);'>
        <!-- التحية -->
        <div style='margin-bottom: 30px;'>
            <h2 style='color: #1a237e; margin: 0 0 15px 0; font-size: 24px;'>مرحباً {employee.FirstName}،</h2>
            <p style='margin: 0; color: #555555; font-size: 16px;'>بطاقة الهوية الرقمية الخاصة بك جاهزة! لقد جعلنا من السهل عليك الوصول إلى بطاقة الهوية الخاصة بك من خلال خيارات متعددة.</p>
        </div>
        <!-- زر الإجراء الرئيسي -->
        <div style='text-align: center; margin: 30px 0;'>
            <a href='{appUrl}/employee-id/{employee.Id}' 
               style='display: inline-block; padding: 14px 30px; background-color: #1a237e; color: #ffffff; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px;'>
                عرض الهوية الرقمية
            </a>
        </div>
        <!-- قسم رمز QR -->
        <div style='text-align: center; margin: 30px 0; padding: 25px; background-color: #ffffff; border: 1px solid #e0e0e0; border-radius: 6px;'>
            <h3 style='color: #1a237e; margin: 0 0 15px 0; font-size: 18px;'>رمز QR للوصول السريع</h3>
            <p style='color: #666666; font-size: 14px; margin-bottom: 15px;'>امسح رمز QR هذا للوصول إلى هويتك الرقمية على أي جهاز</p>
            <img src={employee.DigitalID.QRCode} alt='رمز QR' style='width: 180px; height: 180px; margin: 0 auto;'>
        </div>
        <!-- معلومات الموظف -->
        <div style='background-color: #f8f9fa; padding: 25px; border-radius: 6px; margin: 30px 0;'>
            <h3 style='color: #1a237e; margin: 0 0 15px 0; font-size: 18px;'>معلومات الموظف</h3>
            <table style='width: 100%; border-collapse: collapse;'>
                <tr>
                    <td style='padding: 8px 0; color: #666666; width: 40%;'>رقم الموظف:</td>
                    <td style='padding: 8px 0; color: #333333; font-weight: 500;'>{employee.EmployeeID}</td>
                </tr>
                <tr>
                    <td style='padding: 8px 0; color: #666666;'>القسم:</td>
                    <td style='padding: 8px 0; color: #333333; font-weight: 500;'>{employee.DepartmentName}</td>
                </tr>
                <tr>
                    <td style='padding: 8px 0; color: #666666;'>المنصب:</td>
                    <td style='padding: 8px 0; color: #333333; font-weight: 500;'>{employee.Role}</td>
                </tr>
            </table>
        </div>
        <!-- التذييل -->
        <div style='margin-top: 40px; padding-top: 20px; border-top: 1px solid #e0e0e0; text-align: center;'>
            <p style='color: #666666; font-size: 14px; margin-bottom: 20px;'>
                إذا كان لديك أي أسئلة أو تحتاج إلى مساعدة، يرجى الاتصال بقسم الموارد البشرية.
            </p>
            <p style='color: #666666; font-size: 14px; margin: 0;'>
                مع أطيب التحيات،<br>
                <strong style='color: #1a237e;'>فريق تكنولوجيا المعلومات في {employee.CompanyName}</strong>
            </p>
        </div>
    </div>
</body>
</html>`;
  }

  return `<!DOCTYPE html>
<html>
<head>
    <meta charset='UTF-8'>
    <meta name='viewport' content='width=device-width, initial-scale=1.0'>
</head>
<body style='margin: 0; padding: 0; font-family: Arial, sans-serif; line-height: 1.6; color: #333333; background-color: #f4f4f4;'>
    <div style='max-width: 600px; margin: 0 auto; background-color: #ffffff; padding: 40px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);'>
        <!-- Greeting -->
        <div style='margin-bottom: 30px;'>
            <h2 style='color: #1a237e; margin: 0 0 15px 0; font-size: 24px;'>Hello {employee.FirstName},</h2>
            <p style='margin: 0; color: #555555; font-size: 16px;'>Your digital ID card is ready! We've made it easy for you to access your ID card through multiple options.</p>
        </div>
        <!-- Primary Action Button -->
        <div style='text-align: center; margin: 30px 0;'>
            <a href='{appUrl}/employee-id/{employee.Id}' 
               style='display: inline-block; padding: 14px 30px; background-color: #1a237e; color: #ffffff; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px;'>
                View Your Digital ID
            </a>
        </div>
        <!-- QR Code Section -->
        <div style='text-align: center; margin: 30px 0; padding: 25px; background-color: #ffffff; border: 1px solid #e0e0e0; border-radius: 6px;'>
            <h3 style='color: #1a237e; margin: 0 0 15px 0; font-size: 18px;'>Quick Access QR Code</h3>
            <p style='color: #666666; font-size: 14px; margin-bottom: 15px;'>Scan this QR code to access your digital ID on any device</p>
            <img src={employee.DigitalID.QRCode} alt='QR Code' style='width: 180px; height: 180px; margin: 0 auto;'>
        </div>
        <!-- Employee Details -->
        <div style='background-color: #f8f9fa; padding: 25px; border-radius: 6px; margin: 30px 0;'>
            <h3 style='color: #1a237e; margin: 0 0 15px 0; font-size: 18px;'>Employee Information</h3>
            <table style='width: 100%; border-collapse: collapse;'>
                <tr>
                    <td style='padding: 8px 0; color: #666666; width: 40%;'>Employee ID:</td>
                    <td style='padding: 8px 0; color: #333333; font-weight: 500;'>{employee.EmployeeID}</td>
                </tr>
                <tr>
                    <td style='padding: 8px 0; color: #666666;'>Department:</td>
                    <td style='padding: 8px 0; color: #333333; font-weight: 500;'>{employee.DepartmentName}</td>
                </tr>

                <tr>
                    <td style='padding: 8px 0; color: #666666;'>Role:</td>
                    <td style='padding: 8px 0; color: #333333; font-weight: 500;'>{employee.Role}</td>
                </tr>
            </table>
        </div>
        <!-- Footer -->
        <div style='margin-top: 40px; padding-top: 20px; border-top: 1px solid #e0e0e0; text-align: center;'>
            <p style='color: #666666; font-size: 14px; margin-bottom: 20px;'>
                If you have any questions or need assistance, please contact your HR department.
            </p>
            <p style='color: #666666; font-size: 14px; margin: 0;'>
                Best regards,<br>
                <strong style='color: #1a237e;'>{employee.CompanyName} IT Team</strong>
            </p>
        </div>
    </div>
</body>
</html>`;
};

export default EmailTemplateEditor;
