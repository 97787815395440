import React from "react";
import { Select } from "antd";
import { CountryCodes } from "../../Constants";

const PhoneInput = ({ value, onChange, className }) => {
  // Split phone number into country code and number
  const parsePhoneNumber = (phoneString) => {
    if (!phoneString) return { countryCode: "+1", number: "" };

    // Find the country code from the phone string
    const code = CountryCodes.find((c) => phoneString.startsWith(c.code));
    if (code) {
      return {
        countryCode: code.code,
        number: phoneString.substring(code.code.length).trim(),
      };
    }

    return { countryCode: "+1", number: phoneString };
  };

  const { countryCode, number } = parsePhoneNumber(value);

  const handleCountryCodeChange = (newCode) => {
    onChange(`${newCode}${number}`);
  };

  const handleNumberChange = (e) => {
    const newNumber = e.target.value.replace(/[^0-9]/g, "");
    onChange(`${countryCode}${newNumber}`);
  };

  return (
    <div className="flex gap-2">
      <Select
        showSearch
        value={countryCode}
        onChange={handleCountryCodeChange}
        className="w-32"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {CountryCodes.map(({ code, country }) => (
          <Select.Option key={code} value={code}>
            {`${code} ${country}`}
          </Select.Option>
        ))}
      </Select>
      <input
        type="tel"
        value={number}
        onChange={handleNumberChange}
        className="w-full p-2 border rounded"
        placeholder="xxx-xx-xxx-xx"
      />
    </div>
  );
};

export default PhoneInput;
