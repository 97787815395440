import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Select, Switch } from "antd";
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import AppContext from "../../AppContext";
import { BASE_URL } from "../../Constants";

const UserForm = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    companyID: undefined,
    companyName: "",
    arabicCompanyName: "",
    role: "",
    isActive: true,
  });
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { profile } = useContext(AppContext);
  const roles =
    profile.role === "super_admin"
      ? [
          {
            value: "super_admin",
            label: t("form.roles.superAdmin", "Super Admin"),
          },
          { value: "admin", label: t("form.roles.admin", "Admin") },
          { value: "user", label: t("form.roles.user", "User") },
        ]
      : [
          { value: "admin", label: t("form.roles.admin", "Admin") },
          { value: "user", label: t("form.roles.user", "User") },
        ];

  useEffect(() => {
    if (profile.role === "super_admin") {
      fetchCompanies();
    }
    if (id) {
      fetchUser(id);
    } else {
      if (profile.role !== "super_admin") {
        setFormValues((prev) => ({
          ...prev,
          companyID: profile.companyID,
          companyName: profile.companyName,
          arabicCompanyName: profile?.arabicCompanyName,
        }));
      }
    }
  }, [id]);

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/Lookup/companies`);
      setCompanyList(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUser = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/User/${id}`);
      setFormValues({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        username: response.data.username,
        role: response.data.role,
        companyID: response.data.companyID,
        companyName: response.data.companyName,
        arabicCompanyName: response.data?.arabicCompanyName,
        isActive: response.data.isActive,
      });
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    validateForm();
  };

  const handleToggleChange = (checked) => {
    setFormValues((prev) => ({ ...prev, isActive: checked }));
    validateForm();
  };

  const validateForm = () => {
    const { firstName, lastName, email, username, password, role, companyID } =
      formValues;
    const isValid =
      firstName &&
      lastName &&
      email &&
      username &&
      (id || password) && // Password is only required for new users
      role &&
      companyID;

    setIsFormValid(isValid);
  };

  const handleCompanyChange = (value, option) => {
    setFormValues((prev) => ({
      ...prev,
      companyID: option.value,
      companyName: option.children,
      arabicCompanyName: option.arabicName,
    }));
    validateForm();
  };

  const handleRoleChange = (value) => {
    setFormValues((prev) => ({
      ...prev,
      role: value,
    }));
    validateForm();
  };

  const onFinish = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      ...formValues,
      role: formValues.role.toLowerCase(),
    };

    try {
      if (id) {
        payload.id = id;
        await axios.put(`${BASE_URL}/User`, payload);
      } else {
        await axios.post(`${BASE_URL}/User`, payload);
      }
      toast.success(
        id ? t("messages.success.userUpdate") : t("messages.success.userCreate")
      );
      navigate("/users");
    } catch (error) {
      console.error("Error saving user:", error);
      toast.error(t("messages.error.saveUser", "Failed to save user"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-full bg-transparent p-6">
      <header className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            {id
              ? t("pageTitle.editUser", "Edit User")
              : t("pageTitle.newUser", "New User")}
          </h1>
        </div>
      </header>
      <div className="rounded-lg px-5 py-6 shadow bg-white sm:px-6">
        <form
          onSubmit={onFinish}
          className="grid grid-cols-1 lg:grid-cols-2 gap-3"
        >
          {/* First Name Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("form.firstName")}
            </label>
            <input
              type="text"
              name="firstName"
              placeholder={t("placeholders.enterFirstName")}
              value={formValues.firstName}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {/* Last Name Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("form.lastName")}
            </label>
            <input
              type="text"
              name="lastName"
              placeholder={t("placeholders.enterLastName")}
              value={formValues.lastName}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {/* Email Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("form.email")}
            </label>
            <input
              type="email"
              name="email"
              placeholder={t("placeholders.enterEmail")}
              value={formValues.email}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("form.username")}
            </label>
            <input
              type="text"
              name="username"
              placeholder={t("placeholders.enterUsername")}
              value={formValues.username}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              disabled={!!id}
            />
          </div>

          {!id && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("form.password")}
              </label>
              <input
                type="password"
                name="password"
                placeholder={t("placeholders.enterPassword")}
                value={formValues.password}
                onChange={handleInputChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
          )}

          {profile.role === "super_admin" && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("form.company", "Company")}
              </label>
              <Select
                showSearch
                value={formValues.companyName}
                placeholder={t("placeholders.company.parentCompany")}
                onChange={handleCompanyChange}
                className="mt-1 block w-full"
                optionFilterProp="children"
              >
                {companyList.map((company) => (
                  <Select.Option
                    key={company.id}
                    value={company.id}
                    arabicName={company.arabicName}
                  >
                    {company.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("form.role")}
            </label>
            <Select
              value={formValues.role}
              onChange={handleRoleChange}
              placeholder={t("form.role")}
              className="mt-1 block w-full"
            >
              {roles.map((role) => (
                <Select.Option key={role.value} value={role.value}>
                  {role.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="mb-4 flex items-center">
            <label className="mr-2">{t("form.active")}</label>
            <Switch
              checked={formValues.isActive}
              onChange={handleToggleChange}
            />
          </div>
          <div className="flex col-span-2 justify-end gap-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="flex items-center gap-2 px-3 py-1 bg-red-600 text-white rounded-lg disabled:opacity-50"
              disabled={loading}
            >
              <CloseCircleOutlined />
              <span>{t("buttons.cancel")}</span>
            </button>
            <button
              type="submit"
              className="flex items-center gap-2 px-3 py-1 bg-green-600 text-white rounded-lg disabled:opacity-50"
              disabled={loading || !isFormValid}
            >
              <SaveOutlined />
              <span>
                {loading
                  ? t("buttons.saving")
                  : id
                  ? t("buttons.save")
                  : t("buttons.new")}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForm;
