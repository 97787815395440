// export const BASE_URL = "https://localhost:7003/api";
// export const BASE_URL = "https://future-cards-api.azurewebsites.net/api";
export const BASE_URL = "https://digital-id-api.azurewebsites.net/api";
// export const AppIconPath =
//   "https://fhcdigitalidstoragedev.blob.core.windows.net/images/";

export const Countries = [
  { label: "Afghanistan", code: "AF", value: 100 },
  { label: "Albania", code: "AL", value: 200 },
  { label: "Algeria", code: "DZ", value: 300 },
  { label: "Andorra", code: "AD", value: 400 },
  { label: "Angola", code: "AO", value: 500 },
  { label: "Argentina", code: "AR", value: 600 },
  { label: "Armenia", code: "AM", value: 700 },
  { label: "Australia", code: "AU", value: 800 },
  { label: "Austria", code: "AT", value: 900 },
  { label: "Azerbaijan", code: "AZ", value: 1000 },
  { label: "Bahamas", code: "BS", value: 1010 },
  { label: "Bahrain", code: "BH", value: 1020 },
  { label: "Bangladesh", code: "BD", value: 1100 },
  { label: "Barbados", code: "BB", value: 1030 },
  { label: "Belarus", code: "BY", value: 1200 },
  { label: "Belgium", code: "BE", value: 1300 },
  { label: "Belize", code: "BZ", value: 1040 },
  { label: "Benin", code: "BJ", value: 1050 },
  { label: "Bhutan", code: "BT", value: 1060 },
  { label: "Bolivia", code: "BO", value: 1400 },
  { label: "Bosnia and Herzegovina", code: "BA", value: 1500 },
  { label: "Botswana", code: "BW", value: 1070 },
  { label: "Brazil", code: "BR", value: 1600 },
  { label: "Brunei", code: "BN", value: 1080 },
  { label: "Bulgaria", code: "BG", value: 1700 },
  { label: "Burkina Faso", code: "BF", value: 1090 },
  { label: "Burundi", code: "BI", value: 1100 },
  { label: "Cabo Verde", code: "CV", value: 1110 },
  { label: "Cambodia", code: "KH", value: 1120 },
  { label: "Cameroon", code: "CM", value: 1130 },
  { label: "Canada", code: "CA", value: 1800 },
  { label: "Central African Republic", code: "CF", value: 1140 },
  { label: "Chad", code: "TD", value: 1150 },
  { label: "Chile", code: "CL", value: 1900 },
  { label: "China", code: "CN", value: 2000 },
  { label: "Colombia", code: "CO", value: 2100 },
  { label: "Comoros", code: "KM", value: 1160 },
  { label: "Congo", code: "CG", value: 1170 },
  { label: "Côte d'Ivoire", code: "CI", value: 2300 },
  { label: "Croatia", code: "HR", value: 2400 },
  { label: "Cuba", code: "CU", value: 2500 },
  { label: "Cyprus", code: "CY", value: 2600 },
  { label: "Czech Republic", code: "CZ", value: 2700 },
  { label: "Denmark", code: "DK", value: 2800 },
  { label: "Djibouti", code: "DJ", value: 1180 },
  { label: "Dominica", code: "DM", value: 1190 },
  { label: "Dominican Republic", code: "DO", value: 1200 },
  { label: "Ecuador", code: "EC", value: 2900 },
  { label: "Egypt", code: "EG", value: 3000 },
  { label: "El Salvador", code: "SV", value: 3100 },
  { label: "Equatorial Guinea", code: "GQ", value: 1210 },
  { label: "Eritrea", code: "ER", value: 1220 },
  { label: "Estonia", code: "EE", value: 3200 },
  { label: "Eswatini", code: "SZ", value: 1230 },
  { label: "Ethiopia", code: "ET", value: 3300 },
  { label: "Fiji", code: "FJ", value: 1240 },
  { label: "Finland", code: "FI", value: 3400 },
  { label: "France", code: "FR", value: 3500 },
  { label: "Gabon", code: "GA", value: 1250 },
  { label: "Gambia", code: "GM", value: 1260 },
  { label: "Georgia", code: "GE", value: 1270 },
  { label: "Germany", code: "DE", value: 3600 },
  { label: "Ghana", code: "GH", value: 3700 },
  { label: "Greece", code: "GR", value: 3800 },
  { label: "Grenada", code: "GD", value: 1280 },
  { label: "Guatemala", code: "GT", value: 3900 },
  { label: "Guinea", code: "GN", value: 1290 },
  { label: "Guinea-Bissau", code: "GW", value: 1300 },
  { label: "Guyana", code: "GY", value: 1310 },
  { label: "Haiti", code: "HT", value: 1320 },
  { label: "Honduras", code: "HN", value: 4000 },
  { label: "Hungary", code: "HU", value: 4100 },
  { label: "Iceland", code: "IS", value: 4200 },
  { label: "India", code: "IN", value: 4300 },
  { label: "Indonesia", code: "ID", value: 4400 },
  { label: "Iran", code: "IR", value: 4500 },
  { label: "Iraq", code: "IQ", value: 4600 },
  { label: "Ireland", code: "IE", value: 4700 },
  { label: "Israel", code: "IL", value: 4800 },
  { label: "Italy", code: "IT", value: 4900 },
  { label: "Jamaica", code: "JM", value: 5000 },
  { label: "Japan", code: "JP", value: 5100 },
  { label: "Jordan", code: "JO", value: 5200 },
  { label: "Kazakhstan", code: "KZ", value: 5300 },
  { label: "Kenya", code: "KE", value: 5400 },
  { label: "Kiribati", code: "KI", value: 1330 },
  { label: "North Korea", code: "KP", value: 1340 },
  { label: "South Korea", code: "KR", value: 5500 },
  { label: "Kosovo", code: "XK", value: 5600 },
  { label: "Kuwait", code: "KW", value: 5700 },
  { label: "Kyrgyzstan", code: "KG", value: 5800 },
  { label: "Laos", code: "LA", value: 5900 },
  { label: "Latvia", code: "LV", value: 6000 },
  { label: "Lebanon", code: "LB", value: 6100 },
  { label: "Lesotho", code: "LS", value: 1350 },
  { label: "Liberia", code: "LR", value: 6200 },
  { label: "Libya", code: "LY", value: 6300 },
  { label: "Liechtenstein", code: "LI", value: 1360 },
  { label: "Lithuania", code: "LT", value: 6400 },
  { label: "Luxembourg", code: "LU", value: 6500 },
  { label: "Madagascar", code: "MG", value: 6600 },
  { label: "Malawi", code: "MW", value: 6700 },
  { label: "Malaysia", code: "MY", value: 6800 },
  { label: "Maldives", code: "MV", value: 1370 },
  { label: "Mali", code: "ML", value: 1380 },
  { label: "Malta", code: "MT", value: 1390 },
  { label: "Marshall Islands", code: "MH", value: 1400 },
  { label: "Mauritania", code: "MR", value: 1410 },
  { label: "Mauritius", code: "MU", value: 1420 },
  { label: "Mexico", code: "MX", value: 1430 },
  { label: "Micronesia", code: "FM", value: 1440 },
  { label: "Moldova", code: "MD", value: 1450 },
  { label: "Monaco", code: "MC", value: 1460 },
  { label: "Mongolia", code: "MN", value: 1470 },
  { label: "Montenegro", code: "ME", value: 1480 },
  { label: "Morocco", code: "MA", value: 1490 },
  { label: "Mozambique", code: "MZ", value: 1500 },
  { label: "Myanmar", code: "MM", value: 1510 },
  { label: "Namibia", code: "NA", value: 1520 },
  { label: "Nauru", code: "NR", value: 1530 },
  { label: "Nepal", code: "NP", value: 1540 },
  { label: "Netherlands", code: "NL", value: 1550 },
  { label: "New Zealand", code: "NZ", value: 1560 },
  { label: "Nicaragua", code: "NI", value: 1570 },
  { label: "Niger", code: "NE", value: 1580 },
  { label: "Nigeria", code: "NG", value: 1590 },
  { label: "North Macedonia", code: "MK", value: 1600 },
  { label: "Norway", code: "NO", value: 1610 },
  { label: "Oman", code: "OM", value: 1620 },
  { label: "Pakistan", code: "PK", value: 1630 },
  { label: "Palau", code: "PW", value: 1640 },
  { label: "Palestine", code: "PS", value: 1650 },
  { label: "Panama", code: "PA", value: 1660 },
  { label: "Papua New Guinea", code: "PG", value: 1670 },
  { label: "Paraguay", code: "PY", value: 1680 },
  { label: "Peru", code: "PE", value: 1690 },
  { label: "Philippines", code: "PH", value: 1700 },
  { label: "Poland", code: "PL", value: 1710 },
  { label: "Portugal", code: "PT", value: 1720 },
  { label: "Qatar", code: "QA", value: 1730 },
  { label: "Romania", code: "RO", value: 1740 },
  { label: "Russia", code: "RU", value: 1750 },
  { label: "Rwanda", code: "RW", value: 1760 },
  { label: "Saint Kitts and Nevis", code: "KN", value: 1770 },
  { label: "Saint Lucia", code: "LC", value: 1780 },
  { label: "Saint Vincent and the Grenadines", code: "VC", value: 1790 },
  { label: "Samoa", code: "WS", value: 1800 },
  { label: "San Marino", code: "SM", value: 1810 },
  { label: "Sao Tome and Principe", code: "ST", value: 1820 },
  { label: "Saudi Arabia", code: "SA", value: 1830 },
  { label: "Senegal", code: "SN", value: 1840 },
  { label: "Serbia", code: "RS", value: 1850 },
  { label: "Seychelles", code: "SC", value: 1860 },
  { label: "Sierra Leone", code: "SL", value: 1870 },
  { label: "Singapore", code: "SG", value: 1880 },
  { label: "Slovakia", code: "SK", value: 1890 },
  { label: "Slovenia", code: "SI", value: 1900 },
  { label: "Solomon Islands", code: "SB", value: 1910 },
  { label: "Somalia", code: "SO", value: 1920 },
  { label: "South Africa", code: "ZA", value: 1930 },
  { label: "South Sudan", code: "SS", value: 1940 },
  { label: "Spain", code: "ES", value: 1950 },
  { label: "Sri Lanka", code: "LK", value: 1960 },
  { label: "Sudan", code: "SD", value: 1970 },
  { label: "Suriname", code: "SR", value: 1980 },
  { label: "Sweden", code: "SE", value: 1990 },
  { label: "Switzerland", code: "CH", value: 2000 },
  { label: "Syria", code: "SY", value: 2010 },
  { label: "Taiwan", code: "TW", value: 2020 },
  { label: "Tajikistan", code: "TJ", value: 2030 },
  { label: "Tanzania", code: "TZ", value: 2040 },
  { label: "Thailand", code: "TH", value: 2050 },
  { label: "Timor-Leste", code: "TL", value: 2060 },
  { label: "Togo", code: "TG", value: 2070 },
  { label: "Tonga", code: "TO", value: 2080 },
  { label: "Trinidad and Tobago", code: "TT", value: 2090 },
  { label: "Tunisia", code: "TN", value: 2100 },
  { label: "Turkey", code: "TR", value: 2110 },
  { label: "Turkmenistan", code: "TM", value: 2120 },
  { label: "Tuvalu", code: "TV", value: 2130 },
  { label: "Uganda", code: "UG", value: 2140 },
  { label: "Ukraine", code: "UA", value: 2150 },
  { label: "United Arab Emirates", code: "AE", value: 2160 },
  { label: "United Kingdom", code: "GB", value: 2170 },
  { label: "United States", code: "US", value: 2180 },
  { label: "Uruguay", code: "UY", value: 2190 },
  { label: "Uzbekistan", code: "UZ", value: 2200 },
  { label: "Vanuatu", code: "VU", value: 2210 },
  { label: "Vatican City", code: "VA", value: 2220 },
  { label: "Venezuela", code: "VE", value: 2230 },
  { label: "Vietnam", code: "VN", value: 2240 },
  { label: "Yemen", code: "YE", value: 2250 },
  { label: "Zambia", code: "ZM", value: 2260 },
  { label: "Zimbabwe", code: "ZW", value: 2270 },
];
export const Currencies = [
  { code: "USD", symbol: "$" }, // US Dollar
  { code: "SAR", symbol: "SAR" }, // Saudi Riyal
  { code: "EUR", symbol: "€" }, // Euro
  { code: "GBP", symbol: "£" }, // British Pound
  { code: "INR", symbol: "₹" }, // Indian Rupee
  { code: "JPY", symbol: "¥" }, // Japanese Yen
  { code: "AUD", symbol: "A$" }, // Australian Dollar
];

export const CountryCodes = [
  { code: "+1", country: "United States/Canada" },
  { code: "+20", country: "Egypt" },
  { code: "+27", country: "South Africa" },
  { code: "+30", country: "Greece" },
  { code: "+31", country: "Netherlands" },
  { code: "+32", country: "Belgium" },
  { code: "+33", country: "France" },
  { code: "+34", country: "Spain" },
  { code: "+36", country: "Hungary" },
  { code: "+39", country: "Italy" },
  { code: "+40", country: "Romania" },
  { code: "+41", country: "Switzerland" },
  { code: "+43", country: "Austria" },
  { code: "+44", country: "United Kingdom" },
  { code: "+45", country: "Denmark" },
  { code: "+46", country: "Sweden" },
  { code: "+47", country: "Norway" },
  { code: "+48", country: "Poland" },
  { code: "+49", country: "Germany" },
  { code: "+51", country: "Peru" },
  { code: "+52", country: "Mexico" },
  { code: "+53", country: "Cuba" },
  { code: "+54", country: "Argentina" },
  { code: "+55", country: "Brazil" },
  { code: "+56", country: "Chile" },
  { code: "+57", country: "Colombia" },
  { code: "+58", country: "Venezuela" },
  { code: "+60", country: "Malaysia" },
  { code: "+61", country: "Australia" },
  { code: "+62", country: "Indonesia" },
  { code: "+63", country: "Philippines" },
  { code: "+64", country: "New Zealand" },
  { code: "+65", country: "Singapore" },
  { code: "+66", country: "Thailand" },
  { code: "+81", country: "Japan" },
  { code: "+82", country: "South Korea" },
  { code: "+84", country: "Vietnam" },
  { code: "+86", country: "China" },
  { code: "+90", country: "Turkey" },
  { code: "+91", country: "India" },
  { code: "+92", country: "Pakistan" },
  { code: "+93", country: "Afghanistan" },
  { code: "+94", country: "Sri Lanka" },
  { code: "+95", country: "Myanmar" },
  { code: "+98", country: "Iran" },
  { code: "+211", country: "South Sudan" },
  { code: "+212", country: "Morocco" },
  { code: "+213", country: "Algeria" },
  { code: "+216", country: "Tunisia" },
  { code: "+218", country: "Libya" },
  { code: "+220", country: "Gambia" },
  { code: "+221", country: "Senegal" },
  { code: "+222", country: "Mauritania" },
  { code: "+223", country: "Mali" },
  { code: "+224", country: "Guinea" },
  { code: "+225", country: "Ivory Coast" },
  { code: "+226", country: "Burkina Faso" },
  { code: "+227", country: "Niger" },
  { code: "+228", country: "Togo" },
  { code: "+229", country: "Benin" },
  { code: "+230", country: "Mauritius" },
  { code: "+231", country: "Liberia" },
  { code: "+232", country: "Sierra Leone" },
  { code: "+233", country: "Ghana" },
  { code: "+234", country: "Nigeria" },
  { code: "+235", country: "Chad" },
  { code: "+236", country: "Central African Republic" },
  { code: "+237", country: "Cameroon" },
  { code: "+238", country: "Cape Verde" },
  { code: "+239", country: "Sao Tome and Principe" },
  { code: "+240", country: "Equatorial Guinea" },
  { code: "+241", country: "Gabon" },
  { code: "+242", country: "Republic of the Congo" },
  { code: "+243", country: "Democratic Republic of the Congo" },
  { code: "+244", country: "Angola" },
  { code: "+245", country: "Guinea-Bissau" },
  { code: "+248", country: "Seychelles" },
  { code: "+249", country: "Sudan" },
  { code: "+250", country: "Rwanda" },
  { code: "+251", country: "Ethiopia" },
  { code: "+252", country: "Somalia" },
  { code: "+253", country: "Djibouti" },
  { code: "+254", country: "Kenya" },
  { code: "+255", country: "Tanzania" },
  { code: "+256", country: "Uganda" },
  { code: "+257", country: "Burundi" },
  { code: "+258", country: "Mozambique" },
  { code: "+260", country: "Zambia" },
  { code: "+261", country: "Madagascar" },
  { code: "+262", country: "Reunion" },
  { code: "+263", country: "Zimbabwe" },
  { code: "+264", country: "Namibia" },
  { code: "+265", country: "Malawi" },
  { code: "+266", country: "Lesotho" },
  { code: "+267", country: "Botswana" },
  { code: "+268", country: "Eswatini" },
  { code: "+269", country: "Comoros" },
  { code: "+290", country: "Saint Helena" },
  { code: "+291", country: "Eritrea" },
  { code: "+297", country: "Aruba" },
  { code: "+298", country: "Faroe Islands" },
  { code: "+299", country: "Greenland" },
  { code: "+350", country: "Gibraltar" },
  { code: "+351", country: "Portugal" },
  { code: "+352", country: "Luxembourg" },
  { code: "+353", country: "Ireland" },
  { code: "+354", country: "Iceland" },
  { code: "+355", country: "Albania" },
  { code: "+356", country: "Malta" },
  { code: "+357", country: "Cyprus" },
  { code: "+358", country: "Finland" },
  { code: "+359", country: "Bulgaria" },
  { code: "+370", country: "Lithuania" },
  { code: "+371", country: "Latvia" },
  { code: "+372", country: "Estonia" },
  { code: "+373", country: "Moldova" },
  { code: "+374", country: "Armenia" },
  { code: "+375", country: "Belarus" },
  { code: "+376", country: "Andorra" },
  { code: "+377", country: "Monaco" },
  { code: "+378", country: "San Marino" },
  { code: "+379", country: "Vatican City" },
  { code: "+380", country: "Ukraine" },
  { code: "+381", country: "Serbia" },
  { code: "+382", country: "Montenegro" },
  { code: "+383", country: "Kosovo" },
  { code: "+385", country: "Croatia" },
  { code: "+386", country: "Slovenia" },
  { code: "+387", country: "Bosnia and Herzegovina" },
  { code: "+389", country: "North Macedonia" },
  { code: "+420", country: "Czech Republic" },
  { code: "+421", country: "Slovakia" },
  { code: "+423", country: "Liechtenstein" },
  { code: "+500", country: "Falkland Islands" },
  { code: "+501", country: "Belize" },
  { code: "+502", country: "Guatemala" },
  { code: "+503", country: "El Salvador" },
  { code: "+504", country: "Honduras" },
  { code: "+505", country: "Nicaragua" },
  { code: "+506", country: "Costa Rica" },
  { code: "+507", country: "Panama" },
  { code: "+508", country: "Saint Pierre and Miquelon" },
  { code: "+509", country: "Haiti" },
  { code: "+590", country: "Guadeloupe" },
  { code: "+591", country: "Bolivia" },
  { code: "+592", country: "Guyana" },
  { code: "+593", country: "Ecuador" },
  { code: "+594", country: "French Guiana" },
  { code: "+595", country: "Paraguay" },
  { code: "+596", country: "Martinique" },
  { code: "+597", country: "Suriname" },
  { code: "+598", country: "Uruguay" },
  { code: "+599", country: "Curacao" },
  { code: "+670", country: "East Timor" },
  { code: "+672", country: "Australian External Territories" },
  { code: "+673", country: "Brunei" },
  { code: "+674", country: "Nauru" },
  { code: "+675", country: "Papua New Guinea" },
  { code: "+676", country: "Tonga" },
  { code: "+677", country: "Solomon Islands" },
  { code: "+678", country: "Vanuatu" },
  { code: "+679", country: "Fiji" },
  { code: "+680", country: "Palau" },
  { code: "+681", country: "Wallis and Futuna" },
  { code: "+682", country: "Cook Islands" },
  { code: "+683", country: "Niue" },
  { code: "+685", country: "Samoa" },
  { code: "+686", country: "Kiribati" },
  { code: "+687", country: "New Caledonia" },
  { code: "+688", country: "Tuvalu" },
  { code: "+689", country: "French Polynesia" },
  { code: "+690", country: "Tokelau" },
  { code: "+691", country: "Micronesia" },
  { code: "+692", country: "Marshall Islands" },
  { code: "+850", country: "North Korea" },
  { code: "+852", country: "Hong Kong" },
  { code: "+853", country: "Macau" },
  { code: "+855", country: "Cambodia" },
  { code: "+856", country: "Laos" },
  { code: "+880", country: "Bangladesh" },
  { code: "+886", country: "Taiwan" },
  { code: "+960", country: "Maldives" },
  { code: "+961", country: "Lebanon" },
  { code: "+962", country: "Jordan" },
  { code: "+963", country: "Syria" },
  { code: "+964", country: "Iraq" },
  { code: "+965", country: "Kuwait" },
  { code: "+966", country: "Saudi Arabia" },
  { code: "+967", country: "Yemen" },
  { code: "+968", country: "Oman" },
  { code: "+970", country: "Palestine" },
  { code: "+971", country: "United Arab Emirates" },
  { code: "+972", country: "Israel" },
  { code: "+973", country: "Bahrain" },
  { code: "+974", country: "Qatar" },
  { code: "+975", country: "Bhutan" },
  { code: "+976", country: "Mongolia" },
  { code: "+977", country: "Nepal" },
  { code: "+992", country: "Tajikistan" },
  { code: "+993", country: "Turkmenistan" },
  { code: "+994", country: "Azerbaijan" },
  { code: "+995", country: "Georgia" },
  { code: "+996", country: "Kyrgyzstan" },
  { code: "+998", country: "Uzbekistan" },
];
