import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Select, Switch } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  CloseCircleOutlined,
  SaveOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../Constants";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const SubscriptionForm = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    name: "",
    isActive: true,
    pricingTiers: [
      {
        maxEmployees: "10",
        monthlyPrice: "",
        yearlyPrice: "",
        id: uuidv4(),
        sku: "",
      },
    ],
    features: {
      customizationOptions: false,
      support: "Email",
      apiAccess: false,
    },
  });

  const { navItemsColor, currency } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const generateSKU = (planName, maxEmployees) => {
    if (!planName || !maxEmployees) return "";
    const cleanPlanName = planName
      .replace(/[^a-zA-Z0-9]/g, "")
      .toUpperCase()
      .substring(0, 3);
    const paddedEmployees = maxEmployees.toString().padStart(4, "0");
    return `${cleanPlanName}-${paddedEmployees}`;
  };
  useEffect(() => {
    if (formValues.name) {
      setFormValues((prev) => ({
        ...prev,
        pricingTiers: prev.pricingTiers.map((tier) => ({
          ...tier,
          sku: generateSKU(formValues.name, tier.maxEmployees),
        })),
      }));
    }
  }, [formValues.name]);
  useEffect(() => {
    if (id) {
      fetchSubscription(id);
    }
  }, [id]);

  const fetchSubscription = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/subscription/${id}`);
      // Ensure each pricing tier has an id
      const pricingTiersWithIds = response.data.pricingTiers.map((tier) => ({
        ...tier,
        id: tier.id || uuidv4(),
      }));
      setFormValues({ ...response.data, pricingTiers: pricingTiersWithIds });
    } catch (error) {
      toast.error("Error fetching subscription details");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateYearlyPrice = (monthlyPrice) => {
    return (parseFloat(monthlyPrice) * 12).toFixed(2);
  };

  const handlePricingTierChange = (id, field, value) => {
    setFormValues((prev) => {
      const newPricingTiers = prev.pricingTiers.map((tier) => {
        if (tier.id === id) {
          const updates = { ...tier, [field]: value };
          // Auto-calculate yearly price when monthly price changes, unless yearly price was manually edited
          if (field === "monthlyPrice" && !tier.yearlyPriceManuallySet) {
            updates.yearlyPrice = calculateYearlyPrice(value);
          }
          if (field === "yearlyPrice") {
            updates.yearlyPriceManuallySet = true;
          }
          if (field === "maxEmployees") {
            updates.sku = generateSKU(prev.name, value);
          }
          return updates;
        }
        return tier;
      });
      return { ...prev, pricingTiers: newPricingTiers };
    });
  };

  const addPricingTier = () => {
    setFormValues((prev) => ({
      ...prev,
      pricingTiers: [
        ...prev.pricingTiers,
        {
          maxEmployees: "",
          monthlyPrice: "",
          yearlyPrice: "",
          id: uuidv4(),
        },
      ],
    }));
  };

  const removePricingTier = (idToRemove) => {
    if (formValues.pricingTiers.length > 1) {
      setFormValues((prev) => ({
        ...prev,
        pricingTiers: prev.pricingTiers.filter(
          (tier) => tier.id !== idToRemove
        ),
      }));
    } else {
      toast.warning(t("messages.warning.minimumOneTier"));
    }
  };

  const handleFeatureChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      features: {
        ...prev.features,
        [name]: value,
      },
    }));
  };

  const handleTrialChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      trialPeriod: {
        ...prev.trialPeriod,
        [name]: name === "length" ? parseInt(value) : value,
      },
    }));
  };

  const validateForm = () => {
    if (formValues.pricingTiers.length === 0) {
      toast.error(t("messages.error.minimumOneTier"));
      return false;
    }

    for (const tier of formValues.pricingTiers) {
      if (!tier.maxEmployees || !tier.monthlyPrice || !tier.yearlyPrice) {
        toast.error(t("messages.error.incompleteTier"));
        return false;
      }
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const endpoint = id
        ? `${BASE_URL}/subscription`
        : `${BASE_URL}/subscription`;

      const method = id ? "put" : "post";
      await axios[method](endpoint, formValues);

      toast.success(
        id
          ? t("messages.success.subscriptionUpdate")
          : t("messages.success.subscriptionCreate")
      );
      navigate("/subscriptions");
    } catch (error) {
      toast.error(t("messages.error.saveSubscription"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-full bg-transparent">
      <header className="p-6">
        <h1
          style={{ color: navItemsColor }}
          className="text-3xl font-bold text-white"
        >
          {id
            ? t("pageTitle.editSubscription")
            : t("pageTitle.newSubscription")}
        </h1>
      </header>

      <form onSubmit={onSubmit} className="bg-white rounded-lg shadow-md p-6">
        {/* Previous sections remain the same */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Basic Information */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 border-b pb-2">
              {t("subscription.sections.basicInfo")}
            </h2>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("subscription.form.planName")}
              </label>
              <input
                type="text"
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
                placeholder={t("subscription.placeholders.planName")}
              />
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                checked={formValues.isActive}
                onChange={(checked) =>
                  setFormValues((prev) => ({ ...prev, isActive: checked }))
                }
              />
              <label className="text-sm font-medium text-gray-700">
                {t("form.active")}
              </label>
            </div>
          </div>

          {/* Features */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 border-b pb-2">
              {t("subscription.sections.features")}
            </h2>
            <div>
              <label className="block mb-1 text-sm font-medium text-gray-700">
                {t("subscription.form.supportLevel")}
              </label>
              <Select
                value={formValues.features.support}
                onChange={(value) => handleFeatureChange("support", value)}
                className="w-full"
              >
                <Option value="Email">{t("subscription.support.email")}</Option>
                <Option value="Phone">{t("subscription.support.phone")}</Option>
                <Option value="24/7">
                  {t("subscription.support.fullTime")}
                </Option>
              </Select>
            </div>

            <div className="flex gap-2">
              <Switch
                checked={formValues.features.customizationOptions}
                onChange={(checked) =>
                  handleFeatureChange("customizationOptions", checked)
                }
              />
              <label className="block mb-1 text-sm font-medium text-gray-700">
                {t("subscription.form.allowCustomization")}
              </label>
            </div>
          </div>

          {/* Pricing Table */}
          <div className="space-y-4 md:col-span-2">
            <div className="flex justify-between items-center border-b pb-2">
              <h2 className="text-xl font-semibold text-gray-800">
                {t("subscription.sections.pricingTable")}
              </h2>
              <button
                type="button"
                onClick={addPricingTier}
                className="px-4 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md shadow-sm hover:bg-green-700"
              >
                <PlusOutlined className="mr-1" />
                {t("buttons.add")}
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t("subscription.form.maxEmployees")}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      SKU
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t("subscription.form.monthlyPrice")}({currency})
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t("subscription.form.yearlyPrice")}({currency})
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t("buttons.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {formValues.pricingTiers.map((tier) => (
                    <tr key={tier.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="number"
                          value={tier.maxEmployees}
                          onChange={(e) =>
                            handlePricingTierChange(
                              tier.id,
                              "maxEmployees",
                              e.target.value
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2"
                          // placeholder="e.g. 10"
                          required
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="text"
                          value={tier.sku}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 bg-gray-100"
                          readOnly
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="number"
                          value={tier.monthlyPrice}
                          onChange={(e) =>
                            handlePricingTierChange(
                              tier.id,
                              "monthlyPrice",
                              e.target.value
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2"
                          step="0.01"
                          min="0"
                          required
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="number"
                          value={tier.yearlyPrice}
                          onChange={(e) =>
                            handlePricingTierChange(
                              tier.id,
                              "yearlyPrice",
                              e.target.value
                            )
                          }
                          className="block w-full rounded-md border border-gray-300 px-3 py-2"
                          step="0.01"
                          min="0"
                          required
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <button
                          type="button"
                          onClick={() => removePricingTier(tier.id)}
                          className="text-red-600 hover:text-red-900"
                          disabled={formValues.pricingTiers.length === 1}
                        >
                          <DeleteOutlined className="text-lg" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Trial Period */}
        </div>

        {/* Form Actions */}
        <div className="mt-8 flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate("/subscriptions")}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-red-700 rounded-md shadow-sm hover:bg-red-700"
          >
            <CloseCircleOutlined className="mr-2" />
            {t("buttons.cancel")}
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <SaveOutlined className="mr-2" />
            {id ? t("buttons.update") : t("buttons.save")}{" "}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubscriptionForm;
