import React, { useState, useEffect } from "react";
import LocationPicker from "./LocationPicker";
import { useTranslation } from "react-i18next";

const OfficeFormModal = ({ initialValues, onSubmit, onCancel, country }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    phoneNumber: "",
    faxNumber: "",
    email: "",
    googleLocationLink: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleLocationSelect = (locationData) => {
    setFormData((prev) => ({
      ...prev,
      name: locationData.name || prev.name,
      address: locationData.address,
      city: locationData.city,
      state: locationData.state,
      country: locationData.country,
      postalCode: locationData.postalCode,
      googleLocationLink: locationData.googleLocationLink,
      phoneNumber: locationData.phoneNumber,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name?.trim()) {
      newErrors.name = t("errors.nameRequired");
    }

    if (!formData.address?.trim()) {
      newErrors.address = t("errors.addressRequired");
    }

    if (!formData.city?.trim()) {
      newErrors.city = t("errors.cityRequired");
    }

    if (!formData.country?.trim()) {
      newErrors.country = t("errors.countryRequired");
    }

    if (formData.faxNumber && !/^\d{10,}$/.test(formData.faxNumber)) {
      newErrors.faxNumber = t("errors.faxNumberInvalid");
    }

    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = t("errors.emailInvalid");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      onSubmit(formData);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full h-auto max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">
            {initialValues
              ? t("pageTitle.editOffice")
              : t("pageTitle.newOffice")}
          </h2>
          <button
            onClick={onCancel}
            className="text-gray-500 hover:text-gray-700"
            aria-label={t("closeModal")}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {t("form.office")}
              </label>
              <LocationPicker
                apiKey={"AIzaSyB9dtJwoDszhkMZ2CeD8hR-S-C06CmrfEE"}
                locationLink={formData.googleLocationLink}
                initialAddress={formData.address}
                onLocationSelect={handleLocationSelect}
              />
            </div>
            <div className="mt-1 space-y-2">
              <div className="space-y-1">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("form.name")} <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder={t("placeholders.officeName")}
                  className={`w-full px-3 py-2 border ${
                    errors.name ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none`}
                />
                {errors.name && (
                  <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                )}
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("table.office.address")}{" "}
                  <span className="text-red-500">*</span>
                </label>
                <textarea
                  rows={3}
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder={t("placeholders.description")}
                  className={`w-full px-3 py-2 border ${
                    errors.address ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none`}
                />
                {errors.address && (
                  <p className="text-red-500 text-xs mt-1">{errors.address}</p>
                )}
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("table.office.city")}{" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder={t("placeholders.officePlaceholder")}
                  className={`w-full px-3 py-2 border ${
                    errors.city ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none`}
                />
                {errors.city && (
                  <p className="text-red-500 text-xs mt-1">{errors.city}</p>
                )}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-1">
              <label
                htmlFor="postalCode"
                className="block text-sm font-medium text-gray-700"
              >
                {t("form.postalCode")}
              </label>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                placeholder={t("placeholders.postalCode")}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
              />
            </div>

            <div className="space-y-1">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                {t("form.phone")}
              </label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder={t("placeholders.phone")}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-1">
              <label
                htmlFor="faxNumber"
                className="block text-sm font-medium text-gray-700"
              >
                {t("form.faxNumber")}
              </label>
              <input
                type="text"
                id="faxNumber"
                name="faxNumber"
                value={formData.faxNumber}
                onChange={handleChange}
                placeholder={t("placeholders.faxNumber")}
                className={`w-full px-3 py-2 border ${
                  errors.faxNumber ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none`}
              />
              {errors.faxNumber && (
                <p className="text-red-500 text-xs mt-1">{errors.faxNumber}</p>
              )}
            </div>
          </div>

          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onCancel}
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none"
            >
              {t("buttons.cancel")}
            </button>
            <button
              type="submit"
              className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none"
            >
              {t("buttons.save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OfficeFormModal;
