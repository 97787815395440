import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import { toast } from "react-toastify";
import { GlobalOutlined } from "@ant-design/icons";

const SendConfirmationDialog = ({
  showSendDialog,
  setShowSendDialog,
  employeeData,
  id,
}) => {
  const [sendLoading, setSendLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const handleSendCard = async () => {
    setSendLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/Employee/send-digital-id/${id}`,
        null,
        {
          params: {
            lang: selectedLanguage,
          },
        }
      );
      toast.success("Employee card sent successfully!");
      setShowSendDialog(false);
    } catch (error) {
      console.error("Error sending employee card:", error);
      toast.error(
        error.response?.data?.message || "Failed to send employee card"
      );
    } finally {
      setSendLoading(false);
    }
  };

  if (!showSendDialog) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96 shadow-xl">
        <h3 className="text-lg font-semibold mb-2">Send Employee Card</h3>
        <p className="text-gray-600 mb-4">
          Are you sure you want to send the digital employee card to{" "}
          {employeeData.firstName} {employeeData.lastName}?
        </p>

        <div className="mb-6">
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700 mb-2">
            <GlobalOutlined className="w-4 h-4" />
            Select Language
          </label>
          <div className="flex bg-gray-100 p-1 rounded-lg">
            <button
              onClick={() => setSelectedLanguage("en")}
              className={`flex-1 px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 ${
                selectedLanguage === "en"
                  ? "bg-white text-indigo-600 shadow-sm"
                  : "text-gray-600 hover:text-gray-900"
              }`}
              disabled={sendLoading}
            >
              English
            </button>
            <button
              onClick={() => setSelectedLanguage("ar")}
              className={`flex-1 px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 ${
                selectedLanguage === "ar"
                  ? "bg-white text-indigo-600 shadow-sm"
                  : "text-gray-600 hover:text-gray-900"
              }`}
              disabled={sendLoading}
            >
              العربية
            </button>
          </div>
        </div>

        <div className="flex justify-end gap-3">
          <button
            onClick={() => setShowSendDialog(false)}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md transition-colors duration-200"
            disabled={sendLoading}
          >
            Cancel
          </button>
          <button
            onClick={handleSendCard}
            disabled={sendLoading}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 transition-colors duration-200 flex items-center gap-2"
          >
            {sendLoading ? (
              <>
                <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Sending...
              </>
            ) : (
              "Send"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendConfirmationDialog;
