import React, { useContext } from "react";
import AppContext from "../AppContext";
import SuperadminDashboard from "./dashboard/SuperadminDashboard";
import CompanyDashboard from "./dashboard/CompanyDashboard";

function Dashboard() {
  const authCtx = useContext(AppContext);
  return (
    <>
      {authCtx.profile.role === "super_admin" ? (
        <SuperadminDashboard />
      ) : (
        <CompanyDashboard />
      )}
    </>
  );
}

export default Dashboard;
