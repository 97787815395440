import React, { useState, useEffect, useCallback, useContext } from "react";
import GoogleMapReact from "google-map-react";
import { Input, Card } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import AppContext from "../../AppContext";

const Marker = () => (
  <div className="w-8 h-8 bg-red-500 rounded-full border-2 border-white" />
);

const LocationPicker = ({
  apiKey,
  locationLink,
  onLocationSelect,
  initialAddress,
}) => {
  const [searchInput, setSearchInput] = useState(initialAddress || "");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(2);
  useEffect(() => {
    if (locationLink) {
      const match = locationLink.match(/q=(-?\d+\.\d+),(-?\d+\.\d+)/);
      if (match) {
        const lat = parseFloat(match[1]);
        const lng = parseFloat(match[2]);
        setMapCenter({ lat, lng });
        setZoom(17);
        fetchAddressFromCoordinates(lat, lng);
      }
    }
  }, [locationLink]);

  const fetchAddressFromCoordinates = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        handlePlaceSelect(results[0], null, window.google.maps);
      }
    });
  };

  const handleApiLoaded = ({ map, maps }) => {
    const autocomplete = new maps.places.Autocomplete(
      document.getElementById("location-search"),
      { types: ["establishment", "geocode"] }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      handlePlaceSelect(place, map, maps);
    });
  };

  const handlePlaceSelect = (place, map, maps) => {
    if (!place.geometry) return;

    const { location } = place.geometry;
    const newCenter = {
      lat: location.lat(),
      lng: location.lng(),
    };

    setMapCenter(newCenter);
    setZoom(17);
    setSelectedPlace(place);

    const addressData = {
      name: place.name || "",
      address: place.formatted_address || "",
      location: newCenter,
      phoneNumber: place.international_phone_number || "",
      googleLocationLink: `https://www.google.com/maps?q=${newCenter.lat},${newCenter.lng}`,
    };

    place.address_components?.forEach((component) => {
      const types = component.types;
      if (types.includes("postal_code")) {
        addressData.postalCode = component.long_name;
      } else if (types.includes("country")) {
        addressData.country = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        addressData.state = component.long_name;
      } else if (types.includes("locality")) {
        addressData.city = component.long_name;
      }
    });

    onLocationSelect(addressData);
    setSearchInput(place.formatted_address || "");
  };

  const handleMapClick = useCallback(({ lat, lng, x, y, event }) => {
    setMapCenter({ lat, lng });
    fetchAddressFromCoordinates(lat, lng);
  }, []);

  return (
    <div className="space-y-4">
      <div className="relative">
        <Input
          id="location-search"
          placeholder="Search for a location..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          prefix={<EnvironmentOutlined />}
          style={{ width: "100%" }}
        />
      </div>

      <div style={{ height: "230px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey, libraries: ["places"] }}
          center={mapCenter}
          zoom={zoom}
          onClick={handleMapClick}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
        >
          <Marker lat={mapCenter.lat} lng={mapCenter.lng} />
        </GoogleMapReact>
      </div>

      {/* {selectedPlace && (
        <Card className="p-4">
          <h3 className="font-semibold mb-2">Selected Location</h3>
          <p className="text-sm text-gray-600">
            {selectedPlace.formatted_address}
          </p>
        </Card>
      )} */}
    </div>
  );
};

export default LocationPicker;
