import React from "react";

const TextStyleControl = ({ label, field, cardData, setCardData }) => {
  // Memoize the change handlers to prevent recreating them on every render
  const handleColorChange = React.useCallback(
    (value) => {
      setCardData((prev) => ({
        ...prev,
        textStyles: {
          ...prev.textStyles,
          [field]: {
            ...prev.textStyles[field],
            color: value,
          },
        },
      }));
    },
    [field, setCardData]
  );

  const handleSizeChange = React.useCallback(
    (value) => {
      setCardData((prev) => ({
        ...prev,
        textStyles: {
          ...prev.textStyles,
          [field]: {
            ...prev.textStyles[field],
            size: value,
          },
        },
      }));
    },
    [field, setCardData]
  );

  const handleBoldChange = React.useCallback(
    (checked) => {
      setCardData((prev) => ({
        ...prev,
        textStyles: {
          ...prev.textStyles,
          [field]: {
            ...prev.textStyles[field],
            bold: checked,
          },
        },
      }));
    },
    [field, setCardData]
  );

  const handleItalicChange = React.useCallback(
    (checked) => {
      setCardData((prev) => ({
        ...prev,
        textStyles: {
          ...prev.textStyles,
          [field]: {
            ...prev.textStyles[field],
            italic: checked,
          },
        },
      }));
    },
    [field, setCardData]
  );

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="space-y-2">
        <div className="flex gap-2">
          <div className="flex-1">
            <label className="block text-xs text-gray-500 mb-1">Color</label>
            <div className="flex gap-2">
              <input
                type="color"
                value={cardData.textStyles[field].color}
                onChange={(e) => handleColorChange(e.target.value)}
                className="h-8 w-16"
              />
              <input
                type="text"
                value={cardData.textStyles[field].color}
                onChange={(e) => handleColorChange(e.target.value)}
                className="flex-1 px-3 py-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div className="w-24">
            <label className="block text-xs text-gray-500 mb-1">
              Size (px)
            </label>
            <input
              type="number"
              value={cardData.textStyles[field].size}
              onChange={(e) => handleSizeChange(e.target.value)}
              className="w-full px-3 py-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex gap-4">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={cardData.textStyles[field].bold}
              onChange={(e) => handleBoldChange(e.target.checked)}
              className="h-4 w-4"
            />
            <span className="text-sm">Bold</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={cardData.textStyles[field].italic}
              onChange={(e) => handleItalicChange(e.target.checked)}
              className="h-4 w-4"
            />
            <span className="text-sm">Italic</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default TextStyleControl;
