import "./App.css";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Dashboard from "./components/Dashboard";
import { useContext, useEffect } from "react";
import AppContext from "./AppContext";
import Login from "./components/auth/Login";
import Company from "./components/company/Company";
import CompanyForm from "./components/company/CompanyForm";
import User from "./components/user/User";
import UserForm from "./components/user/UserForm";
import EmployeeList from "./components/employee/Employee";
import EmployeeForm from "./components/employee/EmployeeForm";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Subscription from "./components/subscription/Subscription";
import SubscriptionForm from "./components/subscription/SubscriptionForm";
import Settings from "./components/settings/Settings";
import { adjustShade } from "./utls/adjustShade";
import PublicEmployeeCard from "./components/employee/PublicEmployeeCard";
import ChangePassword from "./components/auth/ChangePassword";
import SignupPage from "./components/SignUp";
function App() {
  const { isLoggedIn, primaryColor } = useContext(AppContext);
  useEffect(() => {
    const shades = {
      50: adjustShade(primaryColor, 40), // Lighten a lot
      100: adjustShade(primaryColor, 30),
      200: adjustShade(primaryColor, 20),
      300: adjustShade(primaryColor, 10),
      400: adjustShade(primaryColor, 5),
      500: primaryColor, // Base color
      600: adjustShade(primaryColor, -5),
      700: adjustShade(primaryColor, -10),
      800: adjustShade(primaryColor, -20),
      900: adjustShade(primaryColor, -30), // Darken a lot
    };

    Object.entries(shades).forEach(([shade, color]) => {
      document.documentElement.style.setProperty(`--indigo-${shade}`, color);
    });
  }, [primaryColor]);
  return (
    <>
      {isLoggedIn ? (
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/companies" element={<Company />} />
              <Route path="/company-form/:id?" element={<CompanyForm />} />
              <Route path="/users" element={<User />} />
              <Route path="/user-form/:id?" element={<UserForm />} />
              <Route path="/employees" element={<EmployeeList />} />
              <Route path="/settings/:id" element={<Settings />} />
              <Route path="/employee-form/:id?" element={<EmployeeForm />} />
              <Route path="/employee-id/:id" element={<PublicEmployeeCard />} />
              <Route path="/subscriptions" element={<Subscription />} />
              <Route
                path="/subscription-form/:id?"
                element={<SubscriptionForm />}
              />
            </Routes>
          </Layout>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/changePassword/:token" element={<ChangePassword />} />
            <Route path="/employee-id/:id" element={<PublicEmployeeCard />} />
            <Route path="/complete-signup" element={<SignupPage />} />
          </Routes>
        </BrowserRouter>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </>
  );
}

export default App;
