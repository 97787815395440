import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, Currencies } from "./Constants";

const AppContext = React.createContext({
  profile: null,
  isLoading: false,
  isLoggedIn: false,
  login: (profile) => {},
  logout: () => {},
  primaryColor: "#4f46e5",
  subscriptionStatus: "active",
});

export const AppContextProvider = (props) => {
  const [profile, setProfile] = useState(() => {
    const profileString = localStorage.getItem("user_profile");
    if (profileString)
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        JSON.parse(profileString).token
      }`;
    return profileString ? JSON.parse(profileString) : null;
  });

  const [primaryColor, setPrimaryColor] = useState("#4f46e5"); // Default color
  const [secondaryColor, setSecondaryColor] = useState("#ffff");
  const [primaryTextColor, setPrimaryTextColor] = useState("#ffff");
  const [maxEmployees, setMaxEmployees] = useState(0);
  const [subscriptionStatus, setStatus] = useState("active");
  const [currency, setCurrency] = useState("$");
  const [cardConfig, setCardConfig] = useState([]);
  const [logo, setLogo] = useState(
    "https://future-horses.com/wp-content/uploads/2024/07/logo.webp"
  ); // Default logo

  useEffect(() => {
    // Set Axios authorization header on app load if profile exists
    if (profile?.token) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${profile.token}`;
      fetchSettings();
    }
  }, [profile]);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Company/settings?id=${profile.companyId}`
      );
      if (response.data) {
        setPrimaryColor(response.data.primaryColor);
        setSecondaryColor(response.data.secondaryColor);
        setPrimaryTextColor(response.data.primaryTextColor);
        setCardConfig(response.data.digitalCardConfig);
        setStatus(response.data.subscriptionStatus);
        setMaxEmployees(response.data.maxEmployees);
        setCurrency(
          Currencies.find((i) => i.code === response.data.currency).symbol
        );
        setLogo(
          response.data.iconUrl ||
            "https://future-horses.com/wp-content/uploads/2024/07/logo.webp"
        );
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const loginHandler = (userProfile) => {
    setProfile(userProfile);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${userProfile.token}`;
    localStorage.setItem("user_profile", JSON.stringify(userProfile));
  };

  const logoutHandler = () => {
    setProfile(null);
    localStorage.removeItem("user_profile");
    delete axios.defaults.headers.common["Authorization"]; // Clear the auth header
  };

  const authCtx = {
    profile: profile,
    maxEmployees: maxEmployees,
    isLoggedIn: !!profile?.token,
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    navItemsColor: primaryTextColor,
    isExpired: subscriptionStatus === "Expired",
    companyLogo: logo,
    currency,
    cardConfig: cardConfig,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AppContext.Provider value={authCtx}>{props.children}</AppContext.Provider>
  );
};

export default AppContext;
