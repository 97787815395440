import React from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  ColumnSeries,
} from "@syncfusion/ej2-react-charts";
import { Card, Typography } from "antd";

const { Title } = Typography;
const monthNames = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};
const MonthlyRevenueBarGraph = ({ monthlyRevenueData }) => {
  // Transform data into Syncfusion chart format
  const chartData = Object.entries(monthlyRevenueData)
    .map(([monthYear, revenue]) => {
      const [year, month] = monthYear.split("-"); // Split into year and month
      return {
        monthYear: `${monthNames[month]} ${year}`,
        month: new Date(`${year}-${month}-01`).toLocaleString("default", {
          month: "long",
        }),
        year: parseInt(year, 10),
        revenue: parseFloat(revenue.toFixed(2)),
        date: new Date(`${year}-${month}-01`), // Create a Date object for sorting
      };
    })
    .sort((a, b) => a.date - b.date); // Sort by date

  // Calculate total annual revenue
  const totalAnnualRevenue = chartData.reduce(
    (sum, item) => sum + item.revenue,
    0
  );

  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Monthly Revenue Breakdown</span>
          <Title level={5} style={{ margin: 0 }}>
            Total Annual Revenue: SAR {totalAnnualRevenue}
          </Title>
        </div>
      }
    >
      <ChartComponent
        id="monthly-revenue-chart"
        primaryXAxis={{
          title: "Month",
          valueType: "Category",
          categories: chartData.map((item) => item.monthYear),
          labelStyle: { size: "12px" },
          labelIntersectAction: "Rotate45",
        }}
        primaryYAxis={{
          title: "Revenue (SAR)",
          labelFormat: "{value}",
        }}
        tooltip={{
          enable: true,
          format: "${point.x}: SAR ${point.y}",
        }}
        height="350"
        width="100%"
      >
        <Inject services={[ColumnSeries, Legend, Tooltip, Category]} />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={chartData}
            xName="monthYear"
            yName="revenue"
            type="Column"
            name="Monthly Revenue"
            fill="#1890ff"
          />
        </SeriesCollectionDirective>
      </ChartComponent>
    </Card>
  );
};

export default MonthlyRevenueBarGraph;
