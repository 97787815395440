import React, { useContext, useEffect, useState } from "react";
import { SettingOutlined, UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import { useTranslation } from "react-i18next";

function ThemeSettings({ initialData }) {
  const { t } = useTranslation();
  const {
    profile,
    companyLogo,
    primaryColor,
    primaryTextColor,
    secondaryColor,
  } = initialData;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    primaryColor: "#4f46e5",
    secondaryColor: "#ffffff",
    primaryTextColor: "#ffffff",
    logo: null,
    logoPreview: null,
  });
  const handleColorChange = (e, colorType) => {
    setFormData((prev) => ({
      ...prev,
      [colorType]: e.target.value,
    }));
  };
  useEffect(() => {
    setFormData({
      primaryColor: primaryColor || "#4f46e5",
      secondaryColor: secondaryColor || "#ffffff",
      primaryTextColor: primaryTextColor || "#ffffff",
      logo: null,
      logoPreview: companyLogo || null,
    });
  }, []);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({
          ...prev,
          logo: file,
          logoPreview: reader.result,
        }));
        toast.success(`${file.name} uploaded successfully`);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    if (formData.logo) {
      data.append("Logo", formData.logo);
    }
    data.append("PrimaryColor", formData.primaryColor);
    data.append("SecondaryColor", formData.secondaryColor);
    data.append("PrimaryTextColor", formData.primaryTextColor);
    data.append("Id", profile.companyId);

    try {
      await axios.put(`${BASE_URL}/Company/settings`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Company settings updated successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error updating company settings:", error);
      toast.error("Failed to update company settings");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      {loading && (
        <div className="absolute inset-0 bg-white/50 flex items-center justify-center rounded-lg">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              {t("settings.theme.companyLogo")}
            </label>
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                {formData.logoPreview ? (
                  <div className="relative w-32 h-32">
                    <img
                      src={formData.logoPreview}
                      alt="Logo Preview"
                      className="w-full h-full object-contain rounded-lg border p-3 bg-slate-200"
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setFormData((prev) => ({
                          ...prev,
                          logo: null,
                          logoPreview: null,
                        }))
                      }
                      className="absolute top-0 right-0 bg-red-500 text-white w-6 h-6 flex items-center justify-center rounded-full hover:bg-red-600"
                    >
                      ×
                    </button>
                  </div>
                ) : (
                  <div className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center bg-gray-50">
                    <UploadOutlined className="text-3xl text-gray-400" />
                  </div>
                )}
              </div>

              <div className="flex-grow">
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleLogoUpload}
                  className="hidden"
                  id="logo-upload"
                />
                <label
                  htmlFor="logo-upload"
                  className="inline-block px-4 py-2 bg-blue-500 text-white rounded-lg cursor-pointer hover:bg-blue-600 transition-colors"
                >
                  {t("settings.theme.chooseFile")}
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  {t("fileUpload.supportedFormats")}{" "}
                  <span className="font-medium">
                    {t("fileUpload.formatTypes")}
                  </span>
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  {t("settings.theme.recommendedSize", { size: "200x200px" })}
                </p>
              </div>
            </div>
          </div>

          {/* Color Settings Section */}
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                {t("settings.theme.primaryColor")}
              </label>
              <div className="flex items-center space-x-4">
                <input
                  type="color"
                  value={formData.primaryColor}
                  onChange={(e) => handleColorChange(e, "primaryColor")}
                  className="w-12 h-12 rounded cursor-pointer"
                />
                <input
                  type="text"
                  value={formData.primaryColor}
                  onChange={(e) => handleColorChange(e, "primaryColor")}
                  className="flex-grow px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  pattern="^#[0-9A-Fa-f]{6}$"
                  placeholder="#000000"
                />
              </div>
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                {t("settings.theme.secondaryColor")}
              </label>
              <div className="flex items-center space-x-4">
                <input
                  type="color"
                  value={formData.secondaryColor}
                  onChange={(e) => handleColorChange(e, "secondaryColor")}
                  className="w-12 h-12 rounded cursor-pointer"
                />
                <input
                  type="text"
                  value={formData.secondaryColor}
                  onChange={(e) => handleColorChange(e, "secondaryColor")}
                  className="flex-grow px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  pattern="^#[0-9A-Fa-f]{6}$"
                  placeholder="#ffffff"
                />
              </div>
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                {t("settings.theme.primaryTextColor")}
              </label>

              <div className="flex items-center space-x-4">
                <input
                  type="color"
                  value={formData.primaryTextColor}
                  onChange={(e) => handleColorChange(e, "primaryTextColor")}
                  className="w-12 h-12 rounded cursor-pointer"
                />
                <input
                  type="text"
                  value={formData.primaryTextColor}
                  onChange={(e) => handleColorChange(e, "primaryTextColor")}
                  className="flex-grow px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  pattern="^#[0-9A-Fa-f]{6}$"
                  placeholder="#ffffff"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end pt-6">
          <button
            type="submit"
            disabled={loading}
            className="px-6 py-2 text-white rounded-lg flex items-center bg-blue-600 space-x-2 hover:opacity-90 transition-opacity disabled:opacity-50"
          >
            <SettingOutlined />
            <span>{loading ? t("buttons.saving") : t("buttons.save")}</span>
          </button>
        </div>
      </form>
    </div>
  );
}

export default ThemeSettings;
