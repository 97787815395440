import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Layout, Row, Col, Form, Input, Button, Divider } from "antd";
import { BASE_URL } from "../../Constants";
import {
  EyeInvisibleFilled,
  EyeFilled,
  ArrowRightOutlined,
} from "@ant-design/icons";

const { Content } = Layout;

const ChangePassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useState({
    new: false,
    confirm: false,
  });

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}/Auth/ConfirmPassword`, {
        token: token,
        password: values.confirmPassword,
      });
      if (res.status === 200) {
        toast.success("Password changed successfully!");
        navigate("/");
      }
    } catch (error) {
      toast.error("Error changing password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <Layout className="min-h-screen overflow-hidden relative">
      <div
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage:
            "url('https://img.freepik.com/free-photo/business-partners-handshake-global-corporate-with-technology-concept_53876-102615.jpg?t=st=1727691319~exp=1727694919~hmac=f4ce481d9edd1546e48a8c8220611b0ad64d4297861155ffc46fc201894a527b&w=996')",
          filter: "blur(8px)",
          transform: "scale(1.1)",
        }}
      ></div>
      <Content className="relative z-10">
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col xs={22} sm={16} md={12} lg={8} xl={6}>
            <div className="bg-white bg-opacity-90 p-8 rounded-lg shadow-md backdrop-filter backdrop-blur-lg">
              <div className="text-center mb-8">
                <h2 className="text-3xl font-extrabold text-gray-900">
                  Change Password
                </h2>
              </div>
              <Form
                form={form}
                name="changePassword"
                onFinish={onFinish}
                layout="vertical"
                className="space-y-6"
              >
                <Form.Item
                  name="newPassword"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password!",
                    },
                  ]}
                >
                  <div className="relative">
                    <Input
                      type={showPassword.new ? "text" : "password"}
                      placeholder="New Password"
                      className="pr-10"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      onClick={() => togglePasswordVisibility("new")}
                    >
                      {showPassword.new ? (
                        <EyeInvisibleFilled className="h-5 w-5 text-gray-400" />
                      ) : (
                        <EyeFilled className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your new password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          setPasswordMatch(true);
                          return Promise.resolve();
                        }
                        setPasswordMatch(false);
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <div className="relative">
                    <Input
                      type={showPassword.confirm ? "text" : "password"}
                      placeholder="Confirm Password"
                      className="pr-10"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      onClick={() => togglePasswordVisibility("confirm")}
                    >
                      {showPassword.confirm ? (
                        <EyeInvisibleFilled className="h-5 w-5 text-gray-400" />
                      ) : (
                        <EyeFilled className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-full"
                    loading={loading}
                    disabled={!passwordMatch}
                  >
                    Change Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ChangePassword;
