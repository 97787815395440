import React, { useState, useEffect, useContext } from "react";
import { Table, Layout, message, Input, Select } from "antd";
import { PlusOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

function User() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  const { profile } = useContext(AppContext);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const lowercasedFilter = searchText.toLowerCase();
    const filtered = users.filter((user) => {
      const matchesSearch =
        user.firstName.toLowerCase().includes(lowercasedFilter) ||
        user.lastName.toLowerCase().includes(lowercasedFilter) ||
        user.email.toLowerCase().includes(lowercasedFilter);

      const matchesCompany =
        !selectedCompany || user.companyName === selectedCompany;

      return matchesSearch && matchesCompany;
    });
    setFilteredUsers(filtered);
  }, [searchText, users, selectedCompany]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/User`);
      setUsers(response.data);
      setFilteredUsers(response.data);
      setLoading(false);

      const uniqueCompanies = [
        ...new Set(response.data.map((user) => user.companyName)),
      ];
      setCompanies(uniqueCompanies);
    } catch (error) {
      console.error("Error fetching users:", error);
      message.error(t("messages.error.fetchUser", "Failed to fetch users"));
      setLoading(false);
    }
  };

  const columns = [
    {
      title: t("form.name"),
      dataIndex: "fullName",
      key: "fullName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) =>
        `${a.firstName} ${a.lastName}`.localeCompare(
          `${b.firstName} ${b.lastName}`
        ),
    },
    {
      title: t("form.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("form.role"),
      dataIndex: "role",
      key: "role",
      render: (role) => {
        switch (role) {
          case "super_admin":
            return t("form.roles.superAdmin");
          case "admin":
            return t("form.roles.admin");
          case "user":
            return t("form.roles.user");
          default:
            return role.charAt(0).toUpperCase() + role.slice(1);
        }
      },
    },
    {
      title: t("form.company"),
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: t("form.createdDate"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) =>
        new Date(text).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
      sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
    },
    {
      title: t("form.active"),
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive) =>
        isActive ? t("form.active") : t("form.inactive", "Inactive"),
    },
    {
      title: t("table.admin.actions"),
      key: "action",
      render: (_, record) => (
        <div className="space-x-2">
          <button
            type="button"
            onClick={() => navigate(`/user-form/${record.id}`)}
            className="bg-indigo-500 hover:bg-indigo-600 text-white px-3 py-2 rounded flex items-center"
          >
            <EditOutlined />
          </button>
        </div>
      ),
    },
  ];

  return (
    <Layout className="min-h-full bg-transparent">
      <header className="py-4 bg-transparent">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            {t("navigation.user")}
          </h1>
        </div>
      </header>
      <Content className="rounded-lg px-5 py-6 shadow bg-white sm:px-6">
        <div className="bg-white">
          <div className="flex justify-between items-center mb-6">
            <Input
              placeholder={t("placeholders.searchUsers", "Search users...")}
              prefix={<SearchOutlined />}
              style={{ width: 300 }}
              onChange={(e) => setSearchText(e.target.value)}
              allowClear
            />

            <div className="space-x-2 flex">
              {profile.role === "super_admin" && (
                <Select
                  placeholder={t("form.company")}
                  style={{ width: 250, marginLeft: 16 }}
                  onChange={(value) => setSelectedCompany(value)}
                  allowClear
                >
                  {companies.map((company) => (
                    <Select.Option key={company} value={company}>
                      {company}
                    </Select.Option>
                  ))}
                </Select>
              )}
              <button
                type="button"
                onClick={() => navigate("/user-form")}
                className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-1.5 rounded-lg flex items-center"
              >
                <PlusOutlined className="mr-2" />
                {t("buttons.new")}
                <span className="lg:inline-block hidden ml-1">
                  {t("navigation.user")}
                </span>
              </button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredUsers}
            scroll={{ x: true }}
            rowKey="id"
            loading={loading}
            className="[&_.ant-table-thead_.ant-table-cell]:bg-gray-100 [&_.ant-table-thead_.ant-table-cell]:text-black"
            rowClassName={(record, index) =>
              `${
                index % 2 === 0 ? "bg-white" : "bg-gray-50"
              } hover:bg-gray-100 transition-colors`
            }
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showQuickJumper: true,
            }}
          />
        </div>
      </Content>
    </Layout>
  );
}

export default User;
