import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../Constants";
import { UserAddOutlined } from "@ant-design/icons";
import { Modal } from "antd";

const PublicEmployeeCard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [config, setConfig] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [isArabic, setIsArabic] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchEmployeeCard = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axios.get(
          `${BASE_URL}/Employee/digital-card/${id}`
        );
        setEmployee(response.data.employee);
        setConfig(response.data.config);
        setCompanyLogo(response.data.companyLogo);
      } catch (err) {
        if (err.response?.data?.isSubscriptionExpired) {
          showSubscriptionExpiredModal();
        } else {
          setError("Failed to load employee card");
          console.error("Error fetching employee card:", err);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeCard();
  }, [id]);
  const showSubscriptionExpiredModal = () => {
    Modal.error({
      title: "Subscription Expired",
      content:
        "Your company's subscription has expired. Please contact your administrator to renew the subscription.",
      okText: "Contact Support",
      onOk: () => {
        // You can add navigation to support page or contact form here
        navigate("/");
      },
    });
  };

  const getTextStyle = (field) => {
    if (!config?.textStyles) return {};
    const textStyle = config.textStyles[field] || {};
    return {
      color: textStyle.color || "#000",
      fontSize: `${textStyle.size}px`,
      fontWeight: textStyle.bold ? "bold" : "normal",
      fontStyle: textStyle.italic ? "italic" : "normal",
      direction: isArabic ? "rtl" : "ltr",
    };
  };

  const handleDownloadVCard = () => {
    if (employee?.digitalID?.vCard) {
      // Convert vCard string to base64
      const base64VCard = isArabic
        ? btoa(unescape(encodeURIComponent(employee.digitalID.vCardArabic)))
        : btoa(employee.digitalID.vCard);
      const fileName = `${
        isArabic
          ? `${employee.arabicContent.firstName}_${employee.arabicContent.lastName}.vcf`
          : `${employee.firstName}_${employee.lastName}.vcf`
      }`;

      return (
        <a
          href={`data:text/vcard;base64,${base64VCard}`}
          download={fileName}
          className="mt-6 flex items-center gap-2 px-6 py-3 bg-white hover:bg-gray-50 rounded-lg shadow-md transition-colors"
        >
          <UserAddOutlined className="w-5 h-5" />
          <span>{isArabic ? "إضافة جهة الاتصال" : "Add Contact"} </span>
        </a>
      );
    }
    return null;
  };

  const getLocalizedContent = (field) => {
    if (
      isArabic &&
      employee?.isArabicSupported &&
      employee?.arabicContent?.[field]
    ) {
      return employee.arabicContent[field];
    }
    return employee?.[field] || "";
  };

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900" />
      </div>
    );
  }

  if (error || !employee || !config) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <div className="text-center">
          <p className="text-red-500 mb-2">Failed to load employee card</p>
          <p className="text-sm text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen absolute top-0 left-0 w-screen bg-gray-100">
      <div className="w-full max-w-md mx-auto py-8 px-4">
        {/* Language Switcher */}
        {employee.isArabicSupported && (
          <div className="flex justify-center mb-6">
            <div className="inline-flex rounded-lg p-1 bg-white shadow-md">
              <button
                onClick={() => setIsArabic(false)}
                className={`px-6 py-2 rounded-md transition-colors ${
                  !isArabic
                    ? "bg-blue-500 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                EN
              </button>
              <button
                onClick={() => setIsArabic(true)}
                className={`px-6 py-2 rounded-md transition-colors ${
                  isArabic
                    ? "bg-blue-500 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                AR
              </button>
            </div>
          </div>
        )}

        {/* Employee Card */}
        <div
          style={{
            backgroundColor: !config.useBackgroundImage
              ? config.backgroundColor
              : undefined,
            backgroundImage:
              config.useBackgroundImage && config.backgroundImageUrl
                ? `url(${config.backgroundImageUrl})`
                : undefined,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="bg-white rounded-lg shadow-lg overflow-hidden relative"
        >
          {/* Overlay div */}
          <div
            className="absolute inset-0 bg-black opacity-40"
            style={{
              backgroundColor: !config.useBackgroundImage
                ? config.backgroundColor
                : undefined,
            }}
          ></div>
          <div className="relative aspect-[2.4] w-full">
            {config.companyLogoVisible && companyLogo && (
              <div className="flex justify-center mt-14">
                <img
                  src={companyLogo}
                  alt="Company Logo"
                  className="h-14 object-contain"
                />
              </div>
            )}
          </div>

          <div className="relative px-6 pb-6">
            {config.profileImageVisible && (
              <div className="absolute -top-16 left-1/2 -translate-x-1/2">
                <img
                  src={employee.profileUrl || `./user-placeholder.png`}
                  alt="Profile"
                  className="w-32 h-32 rounded-full border-4 border-white object-cover"
                />
              </div>
            )}

            <div className="pt-20 text-center">
              {config.nameVisible && (
                <h3 style={getTextStyle("name")} className="mb-1">
                  {`${getLocalizedContent("firstName")} ${getLocalizedContent(
                    "lastName"
                  )}`}
                </h3>
              )}
              {config.designationVisible && (
                <p style={getTextStyle("designation")}>
                  {getLocalizedContent("role")}
                </p>
              )}
            </div>

            <div className="mt-6 space-y-1 text-center">
              {config.phoneVisible && (
                <p style={getTextStyle("phone")}>{employee.phone}</p>
              )}
              {config.emailVisible && (
                <p style={getTextStyle("email")}>{employee.email}</p>
              )}
              {config.employeeIdVisible && (
                <p style={getTextStyle("employeeId")}>{employee.employeeID}</p>
              )}
            </div>

            <div
              className="mt-6 w-60 flex mx-auto border-t"
              style={{
                borderTop: `2px solid ${config.dividerColor}`,
              }}
            />

            <div className="pt-4 mb-4">
              {config.companyNameVisible && (
                <p style={getTextStyle("companyName")} className="text-center">
                  {getLocalizedContent("companyName")}
                </p>
              )}
            </div>

            {config.qrCodeVisible && employee.digitalID?.qrCode && (
              <div className="flex justify-center">
                <img
                  src={employee.digitalID.qrCode}
                  alt="QR Code"
                  width="100px"
                />
              </div>
            )}
          </div>
        </div>

        {/* Download & Contact Buttons */}
        <div className="mt-6 mb-40 flex flex-col items-center">
          {employee?.digitalID?.vCard && handleDownloadVCard()}
        </div>
      </div>
    </div>
  );
};

export default PublicEmployeeCard;
