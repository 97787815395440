import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  BankOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AppContext from "../../AppContext";

const TabNavigation = ({ activeTab, setActiveTab, id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profile } = useContext(AppContext);

  // Define the tabs with their properties and translated names
  const tabs = [
    {
      name: t("tabs.details"),
      id: "details",
      icon: BankOutlined,
      current: activeTab === "details",
    },
    {
      name: t("tabs.offices"),
      id: "offices",
      icon: EnvironmentOutlined,
      current: activeTab === "offices",
    },
    {
      name: t("tabs.departments"),
      id: "departments",
      icon: TeamOutlined,
      current: activeTab === "departments",
    },
    {
      name: t("tabs.admins"),
      id: "admins",
      icon: UserOutlined,
      current: activeTab === "admins",
    },
    {
      name: t("tabs.subscription"),
      id: "subscription",
      icon: TeamOutlined,
      current: activeTab === "subscription",
    },
  ];

  // Function to handle navigation to settings
  const onSettingsClick = () => {
    navigate(`/settings/${id}`);
  };

  // Function to handle classNames conditionally
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  // Function to handle tab click
  const handleTabClick = (tabId) => {
    if (tabId === "details" || id) {
      setActiveTab(tabId);
    } else {
      toast.error(t("messages.error.saveCompanyDetails"));
    }
  };

  return (
    <div>
      {/* Mobile View */}
      <div className="sm:hidden mb-3">
        <div className="flex items-center justify-between gap-4">
          <label htmlFor="tabs" className="sr-only">
            {t("tabs.select")}
          </label>
          <select
            id="tabs"
            name="tabs"
            value={activeTab}
            onChange={(e) => handleTabClick(e.target.value)}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
          >
            {tabs.map((tab) => (
              <option key={tab.id} value={tab.id}>
                {tab.name}
              </option>
            ))}
          </select>
          {profile.role === "super_admin" && (
            <button
              onClick={onSettingsClick}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <SettingOutlined className="h-5 w-5" />
              <span className="ml-2">{t("tabs.settings")}</span>
            </button>
          )}
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden sm:block mb-4">
        <div className="border-b border-gray-200">
          <div className="flex items-center justify-between">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => {
                const Icon = tab.icon;
                return (
                  <button
                    key={tab.id}
                    onClick={() => handleTabClick(tab.id)}
                    className={classNames(
                      tab.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    <Icon
                      className={classNames(
                        tab.current
                          ? "text-indigo-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "-ml-0.5 mr-2 h-5 w-5"
                      )}
                    />
                    <span>{tab.name}</span>
                  </button>
                );
              })}
            </nav>
            {profile.role === "super_admin" && (
              <button
                onClick={onSettingsClick}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <SettingOutlined className="h-5 w-5" />
                <span className="ml-2">{t("tabs.companySettings")}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabNavigation;
