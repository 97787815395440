import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate and NavLink
import AppContext from "../AppContext";
import { Avatar } from "antd";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export default function Layout({ children }) {
  const { t } = useTranslation();
  const authCtx = useContext(AppContext);
  const [showRenewalBanner, setShowRenewalBanner] = useState(true);
  const { secondaryColor, navItemsColor, companyLogo, isExpired } = authCtx;
  const location = useLocation();
  const superAdminNavigation = [
    { name: t("navigation.dashboard"), href: "/", current: true },
    { name: t("navigation.company"), href: "companies", current: false },
    { name: t("navigation.user"), href: "users", current: false },
    {
      name: t("navigation.subscription"),
      href: "subscriptions",
      current: false,
    },
  ];

  const adminNavigation = [
    // { name: t("navigation.dashboard"), href: "/", current: true },
    { name: t("navigation.company"), href: "companies", current: false },
    { name: t("navigation.employee"), href: "employees", current: false },
  ];
  const [currentNavItem, setCurrentNavItem] = useState("Dashboard"); // Default value
  const navigation =
    authCtx.profile.role === "super_admin"
      ? superAdminNavigation
      : adminNavigation;
  useEffect(() => {
    // Remove leading slash from the pathname
    const currentPath = location.pathname.replace(/^\/+/, "");

    // Find the current nav item based on the URL
    const navItem =
      navigation.find((item) => item.href === currentPath) ||
      navigation.find((item) => currentPath.includes(item.href));

    // Set the current navigation item name
    if (navItem) {
      setCurrentNavItem(capitalizeFirstLetter(navItem.name));
    } else {
      setCurrentNavItem("Dashboard"); // Fallback
    }
  }, [location]); // Runs whenever location changes
  const navigate = useNavigate();
  const handleSignOut = () => {
    authCtx.logout(); // Call the logout function
    navigate("/"); // Redirect to the login page or another appropriate page
  };
  const userNavigation = [
    { name: "Your Profile", href: "/" },
    { name: "Settings", href: `/settings/${authCtx.profile?.companyId}` },
    { name: "Sign out", href: "" },
  ];
  const SubscriptionBanner = () => (
    <div className="relative bg-red-600 px-4 py-2">
      <div className="mx-auto max-w-7xl flex items-center justify-between">
        <div className="flex items-center flex-1">
          <p className="font-medium text-white">
            <span className="hidden md:inline">
              Your subscription has expired. Renew now to continue accessing all
              features.
            </span>
            <span className="md:hidden">Subscription expired. Renew now.</span>
          </p>
        </div>
        <div className="ml-4 flex-shrink-0">
          <NavLink
            to={`/company-form/${authCtx.profile.companyId}`}
            className="rounded-md bg-red-800 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
          >
            Renew Now
          </NavLink>
        </div>
        <button
          onClick={() => setShowRenewalBanner(false)}
          className="ml-2 text-white hover:text-red-100"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );

  return (
    <>
      <div
        style={{ backgroundColor: secondaryColor }}
        className={`min-h-screen`}
      >
        <div className="bg-indigo-600 pb-32">
          {isExpired && showRenewalBanner && <SubscriptionBanner />}
          <Disclosure
            as="nav"
            className="border-b border-indigo-300 border-opacity-25 bg-indigo-600 lg:border-none"
          >
            <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
              <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                <div className="flex items-center px-2 lg:px-0">
                  <div className="flex-shrink-0">
                    <img
                      alt="Your Company"
                      src={companyLogo}
                      className="block h-8"
                    />
                  </div>
                  <div className="hidden lg:ml-10 lg:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href} // Use NavLink's to prop
                          style={{ color: navItemsColor }}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-indigo-700 "
                                : " hover:bg-indigo-500 hover:bg-opacity-75",
                              "rounded-md px-3 py-2 text-sm font-medium"
                            )
                          }
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-1 justify-end px-2 lg:ml-6 lg:justify-end">
                  <LanguageSwitcher />

                  <div className="flex items-center mx-3">
                    <div className="flex items-center">
                      <div className="flex flex-col">
                        <span
                          style={{ color: navItemsColor }}
                          className="text-sm font-medium "
                        >
                          {authCtx.profile.firstName} {authCtx.profile.lastName}
                        </span>
                        <span
                          style={{ color: navItemsColor }}
                          className="text-xs  opacity-80"
                        >
                          {authCtx.profile.companyName}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex lg:hidden">
                  {/* Mobile menu button */}
                  <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon
                      aria-hidden="true"
                      className="block h-6 w-6 group-data-[open]:hidden"
                    />
                    <XMarkIcon
                      aria-hidden="true"
                      className="hidden h-6 w-6 group-data-[open]:block"
                    />
                  </DisclosureButton>
                </div>
                <div className="hidden lg:ml-4 lg:block">
                  <div className="flex items-center">
                    {/* <button
                      type="button"
                      className="relative flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon aria-hidden="true" className="h-6 w-6" />
                    </button> */}

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3 flex-shrink-0">
                      <div>
                        <MenuButton className="relative flex rounded-full bg-indigo-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <Avatar
                            className="h-28 w-28"
                            size={32}
                            icon={
                              <UserCircleIcon className="h-24 w-24 text-white" />
                            }
                          />
                        </MenuButton>
                      </div>
                      <MenuItems
                        transition
                        className="absolute cursor-pointer right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        {userNavigation.map((item) => (
                          <MenuItem key={item.name}>
                            <a
                              href={
                                item.name === "Sign out" ? undefined : item.href
                              }
                              onClick={
                                item.name === "Sign out"
                                  ? handleSignOut
                                  : undefined
                              } // Call handleSignOut on click
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                            >
                              {item.name}
                            </a>
                          </MenuItem>
                        ))}
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <DisclosurePanel className="lg:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <DisclosureButton
                    key={item.name}
                    as={NavLink} // Change to NavLink for mobile view
                    to={item.href}
                    aria-current={item.current ? "page" : undefined}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? "bg-indigo-700 text-white"
                          : "text-white hover:bg-indigo-500 hover:bg-opacity-75",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )
                    }
                  >
                    {item.name}
                  </DisclosureButton>
                ))}
              </div>
              <div className="border-t border-indigo-700 pb-3 pt-4">
                {/* <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <Avatar
                      className="h-28 w-28 bg-indigo-500"
                      size={32}
                      icon={<UserCircleIcon className="h-24 w-24 text-white" />}
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium text-indigo-300">
                      {user.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div> */}
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <DisclosureButton
                      key={item.name}
                      as="a"
                      href={item.name === "Sign out" ? undefined : item.href}
                      onClick={
                        item.name === "Sign out" ? handleSignOut : undefined
                      } // Call handleSignOut on click
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-50 hover:bg-gray-100"
                    >
                      {item.name}
                    </DisclosureButton>
                  ))}
                </div>
              </div>
            </DisclosurePanel>
          </Disclosure>
          {/* <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">
                {currentNavItem}
              </h1>
            </div>
          </header> */}
        </div>

        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="">{children}</div>
          </div>
        </main>
      </div>
    </>
  );
}
