import React, { useContext, useState } from "react";
import AppContext from "../../AppContext";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import { toast } from "react-toastify";
import TextStyleControl from "./TextStyleControl";
import InputWithVisibility from "./InputWithVisibility";
import { useTranslation } from "react-i18next";

const CustomizeDigitalCard = ({ companyLogo, id, cardConfig }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("content");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardData, setCardData] = useState({
    isCompanyLogo: cardConfig?.companyLogoVisible ?? true,
    companyLogoVisible: cardConfig?.companyLogoVisible ?? true,
    profileImage: null,
    profileImageVisible: cardConfig?.profileImageVisible ?? true,
    backgroundImageUrl: cardConfig?.backgroundImageUrl ?? null,
    backgroundImage: null,
    name: "Name Surname",
    nameVisible: cardConfig?.nameVisible ?? true,
    designation: "Designation",
    designationVisible: cardConfig?.designationVisible ?? true,
    phone: "+123-456-7890",
    phoneVisible: cardConfig?.phoneVisible ?? true,
    email: "hello@example.com",
    emailVisible: cardConfig?.emailVisible ?? true,
    employeeId: "EMP123",
    employeeIdVisible: cardConfig?.employeeIdVisible ?? true,
    companyName: "Future Industries",
    companyNameVisible: cardConfig?.companyNameVisible ?? true,
    backgroundColor: cardConfig?.backgroundColor ?? "#000147",
    useBackgroundImage: cardConfig?.useBackgroundImage ?? false,
    showQrCode: cardConfig?.qrCodeVisible ?? false,
    qrCodeVisible: cardConfig?.qrCodeVisible ?? true,
    dividerColor: cardConfig?.dividerColor ?? "#e5e7eb",
    textStyles: {
      name: {
        color: cardConfig?.textStyles?.name?.color ?? "#ffffff",
        size: cardConfig?.textStyles?.name?.size ?? "18",
        bold: cardConfig?.textStyles?.name?.bold ?? true,
        italic: cardConfig?.textStyles?.name?.italic ?? false,
      },
      designation: {
        color: cardConfig?.textStyles?.designation?.color ?? "#ffffff",
        size: cardConfig?.textStyles?.designation?.size ?? "14",
        bold: cardConfig?.textStyles?.designation?.bold ?? false,
        italic: cardConfig?.textStyles?.designation?.italic ?? false,
      },
      phone: {
        color: cardConfig?.textStyles?.phone?.color ?? "#ffffff",
        size: cardConfig?.textStyles?.phone?.size ?? "12",
        bold: cardConfig?.textStyles?.phone?.bold ?? false,
        italic: cardConfig?.textStyles?.phone?.italic ?? false,
      },
      email: {
        color: cardConfig?.textStyles?.email?.color ?? "#ffffff",
        size: cardConfig?.textStyles?.email?.size ?? "12",
        bold: cardConfig?.textStyles?.email?.bold ?? false,
        italic: cardConfig?.textStyles?.email?.italic ?? false,
      },
      employeeId: {
        color: cardConfig?.textStyles?.employeeId?.color ?? "#ffffff",
        size: cardConfig?.textStyles?.employeeId?.size ?? "12",
        bold: cardConfig?.textStyles?.employeeId?.bold ?? false,
        italic: cardConfig?.textStyles?.employeeId?.italic ?? false,
      },
      companyName: {
        color: cardConfig?.textStyles?.companyName?.color ?? "#ffffff",
        size: cardConfig?.textStyles?.companyName?.size ?? "16",
        bold: cardConfig?.textStyles?.companyName?.bold ?? false,
        italic: cardConfig?.textStyles?.companyName?.italic ?? false,
      },
    },
  });
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const formData = new FormData();

      // Append cardData fields individually
      Object.keys(cardData).forEach((key) => {
        if (key === "textStyles") {
          // Handle textStyles specially
          Object.entries(cardData.textStyles).forEach(
            ([styleKey, styleValue]) => {
              // Append each property of the TextStyle object
              Object.entries(styleValue).forEach(([property, value]) => {
                formData.append(
                  `Config.TextStyles[${styleKey}].${property}`,
                  value.toString()
                );
              });
            }
          );
        } else if (key !== "backgroundImage") {
          // Handle all other fields normally
          formData.append(`Config.${key}`, cardData[key]);
        }
      });

      // Append company ID
      formData.append("CompanyId", id);

      // If there's a background image, convert it and append to the form data
      if (cardData.backgroundImage) {
        const base64Response = await fetch(cardData.backgroundImage);
        const blob = await base64Response.blob();
        formData.append("BackgroundImage", blob, "background.jpg");
      }

      // Send the request
      const response = await axios.put(
        `${BASE_URL}/Company/card-config`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Configuration saved successfully");
    } catch (error) {
      toast.error("Error saving configuration");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUpload = (type, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCardData((prev) => ({
          ...prev,
          [type]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const getTextStyle = (field) => ({
    color: cardData.textStyles[field].color,
    fontSize: `${cardData.textStyles[field].size}px`,
    fontWeight: cardData.textStyles[field].bold ? "bold" : "normal",
    fontStyle: cardData.textStyles[field].italic ? "italic" : "normal",
  });
  return (
    <div className="flex min-h-screen gap-8 p-6 bg-gray-50">
      {/* Left Side - Customization Panel */}
      <div className="w-1/2 flex flex-col items-end">
        <div className="w-full">
          <div className="flex gap-2 mb-6">
            <button
              onClick={() => setActiveTab("content")}
              className={`px-4 py-2 rounded-lg ${
                activeTab === "content"
                  ? "bg-blue-500 text-white"
                  : "bg-white text-gray-700 hover:bg-gray-100"
              }`}
            >
              {t("digitalCard.tabs.content")}
            </button>
            <button
              onClick={() => setActiveTab("style")}
              className={`px-4 py-2 rounded-lg ${
                activeTab === "style"
                  ? "bg-blue-500 text-white"
                  : "bg-white text-gray-700 hover:bg-gray-100"
              }`}
            >
              {t("digitalCard.tabs.style")}
            </button>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            {activeTab === "content" && (
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="isCompanyLogo"
                    checked={cardData.isCompanyLogo}
                    onChange={(e) =>
                      setCardData((prev) => ({
                        ...prev,
                        isCompanyLogo: e.target.checked,
                      }))
                    }
                    className="h-4 w-4"
                  />
                  <label
                    htmlFor="isCompanyLogo"
                    className="text-sm font-medium text-gray-700"
                  >
                    {t("digitalCard.content.showCompanyLogo")}
                  </label>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t("form.profileImage")}
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload("profileImage", e)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <InputWithVisibility
                  label={t("form.name")}
                  field="name"
                  value={cardData.name}
                  onChange={(e) =>
                    setCardData((prev) => ({ ...prev, name: e.target.value }))
                  }
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <InputWithVisibility
                  label={t("form.role")}
                  field="designation"
                  value={cardData.designation}
                  onChange={(e) =>
                    setCardData((prev) => ({
                      ...prev,
                      designation: e.target.value,
                    }))
                  }
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <InputWithVisibility
                  label={t("form.phone")}
                  field="phone"
                  value={cardData.phone}
                  onChange={(e) =>
                    setCardData((prev) => ({ ...prev, phone: e.target.value }))
                  }
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <InputWithVisibility
                  label={t("form.email")}
                  field="email"
                  value={cardData.email}
                  onChange={(e) =>
                    setCardData((prev) => ({ ...prev, email: e.target.value }))
                  }
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <InputWithVisibility
                  label={t("form.employeeID")}
                  field="employeeId"
                  value={cardData.employeeId}
                  onChange={(e) =>
                    setCardData((prev) => ({
                      ...prev,
                      employeeId: e.target.value,
                    }))
                  }
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <InputWithVisibility
                  label={t("form.company.name")}
                  field="companyName"
                  value={cardData.companyName}
                  onChange={(e) =>
                    setCardData((prev) => ({
                      ...prev,
                      companyName: e.target.value,
                    }))
                  }
                  cardData={cardData}
                  setCardData={setCardData}
                />

                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="showQrCode"
                    checked={cardData.showQrCode}
                    onChange={(e) =>
                      setCardData((prev) => ({
                        ...prev,
                        showQrCode: e.target.checked,
                      }))
                    }
                    className="h-4 w-4"
                  />
                  <label
                    htmlFor="showQrCode"
                    className="text-sm font-medium text-gray-700"
                  >
                    {t("digitalCard.content.showQRCode")}
                  </label>
                </div>
              </div>
            )}

            {activeTab === "style" && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {t("digitalCard.style.background")}
                  </label>
                  <div className="space-y-3">
                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        id="useColor"
                        checked={!cardData.useBackgroundImage}
                        onChange={() =>
                          setCardData((prev) => ({
                            ...prev,
                            useBackgroundImage: false,
                          }))
                        }
                        className="h-4 w-4"
                      />
                      <label htmlFor="useColor">
                        {t("digitalCard.style.useColor")}
                      </label>
                    </div>
                    {!cardData.useBackgroundImage && (
                      <div className="flex gap-2 ml-6">
                        <input
                          type="color"
                          value={cardData.backgroundColor}
                          onChange={(e) =>
                            setCardData((prev) => ({
                              ...prev,
                              backgroundColor: e.target.value,
                            }))
                          }
                          className="h-10 w-20"
                        />
                        <input
                          type="text"
                          value={cardData.backgroundColor}
                          onChange={(e) =>
                            setCardData((prev) => ({
                              ...prev,
                              backgroundColor: e.target.value,
                            }))
                          }
                          className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                    )}

                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        id="useImage"
                        checked={cardData.useBackgroundImage}
                        onChange={() =>
                          setCardData((prev) => ({
                            ...prev,
                            useBackgroundImage: true,
                          }))
                        }
                        className="h-4 w-4"
                      />
                      <label htmlFor="useImage">Use Image</label>
                    </div>
                    {cardData.useBackgroundImage && (
                      <div className="ml-6">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            handleImageUpload("backgroundImage", e)
                          }
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <TextStyleControl
                  label={t("form.name")}
                  field="name"
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <TextStyleControl
                  label={t("form.role")}
                  field="designation"
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <TextStyleControl
                  label={t("form.phone")}
                  field="phone"
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <TextStyleControl
                  label={t("form.email")}
                  field="email"
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <TextStyleControl
                  label={t("form.employeeID")}
                  field="employeeId"
                  cardData={cardData}
                  setCardData={setCardData}
                />
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("digitalCard.style.dividerLineStyle")}
                  </label>
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <label className="block text-xs text-gray-500 mb-1">
                        {t("digitalCard.style.color")}
                      </label>
                      <input
                        type="color"
                        value={cardData.dividerColor}
                        onChange={(e) =>
                          setCardData((prev) => ({
                            ...prev,
                            dividerColor: e.target.value,
                          }))
                        }
                        className="h-8 w-16"
                      />
                    </div>
                  </div>
                </div>
                <TextStyleControl
                  label={t("form.company.name")}
                  field="companyName"
                  cardData={cardData}
                  setCardData={setCardData}
                />
              </div>
            )}
          </div>
        </div>
        <button
          onClick={handleSubmit}
          disabled={isSubmitting}
          className="w-fit mt-6 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-400"
        >
          {isSubmitting
            ? t("digitalCard.buttons.saving")
            : t("digitalCard.buttons.saveConfiguration")}
        </button>
      </div>

      {/* Right Side - Preview */}
      <div className="w-1/2">
        <div className="sticky ml-16 top-6">
          <h2 className="text-xl w-96 font-semibold mb-4 text-center">
            {t("digitalCard.preview")}
          </h2>
          <div
            style={{
              backgroundColor: !cardData.useBackgroundImage
                ? cardData.backgroundColor
                : undefined,
              backgroundImage:
                cardData.useBackgroundImage &&
                (cardData.backgroundImage || cardData.backgroundImageUrl)
                  ? `url(${
                      cardData.backgroundImage || cardData.backgroundImageUrl
                    })`
                  : undefined,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="bg-white rounded-lg w-96 shadow-lg overflow-hidden relative"
          >
            {/* Overlay div */}
            <div
              className="absolute inset-0 bg-black opacity-40"
              style={{
                backgroundColor: !cardData.useBackgroundImage
                  ? cardData.backgroundColor
                  : undefined,
              }}
            ></div>
            <div className="relative aspect-[2.4] w-full">
              {cardData.isCompanyLogo && (
                <div className="flex justify-center mt-14">
                  <img
                    src={companyLogo}
                    alt="Company Logo"
                    className="h-14 object-contain"
                  />
                </div>
              )}
            </div>

            <div className="relative px-6 pb-6">
              <div className="absolute -top-16 left-1/2 -translate-x-1/2">
                <img
                  src={cardData.profileImage || `./user-placeholder.png`}
                  alt="Profile"
                  className="w-32 h-32 rounded-full border-4 border-white object-cover"
                />
              </div>

              <div className="pt-20 text-center">
                {cardData.nameVisible && (
                  <h3 style={getTextStyle("name")} className="mb-1">
                    {cardData.name}
                  </h3>
                )}
                {cardData.designationVisible && (
                  <p style={getTextStyle("designation")}>
                    {cardData.designation}
                  </p>
                )}
              </div>

              <div className="mt-6 space-y-1 text-center">
                {cardData.phoneVisible && (
                  <p style={getTextStyle("phone")}>{cardData.phone}</p>
                )}
                {cardData.emailVisible && (
                  <p style={getTextStyle("email")}>{cardData.email}</p>
                )}
                {cardData.employeeIdVisible && (
                  <p style={getTextStyle("employeeId")}>
                    {cardData.employeeId}
                  </p>
                )}
              </div>

              <div
                className="mt-6 w-60 flex mx-auto border-t"
                style={{
                  borderTop: `2px solid ${cardData.dividerColor}`,
                }}
              />
              <div className=" pt-4 mb-4">
                {cardData.companyNameVisible && (
                  <p
                    style={getTextStyle("companyName")}
                    className="text-center"
                  >
                    {cardData.companyName}
                  </p>
                )}
              </div>

              {cardData.showQrCode && (
                <div className=" flex justify-center">
                  <img src="/qr-sample.png" alt="QR Code" width="100px" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizeDigitalCard;
