import React from "react";
import { Card, Row, Col, Typography, Space } from "antd";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationLegend,
  AccumulationTooltip,
  AccumulationDataLabel,
  Inject,
} from "@syncfusion/ej2-react-charts";

const { Text } = Typography;

const RevenueComparison = ({ data }) => {
  // Fallback to sample data or create default values
  const getCurrentData = () => {
    // If API data is provided
    if (data?.globalStatistics?.totalSubscriptionRevenue) {
      return {
        current: data.globalStatistics.totalSubscriptionRevenue,
        previous: data.globalStatistics.totalSubscriptionRevenue * 0.9, // Estimate previous as 90%
        monthlyData: [
          {
            current: data.globalStatistics.totalSubscriptionRevenue / 2,
            previous: data.globalStatistics.totalSubscriptionRevenue / 2.2,
          },
          {
            current: data.globalStatistics.totalSubscriptionRevenue,
            previous: data.globalStatistics.totalSubscriptionRevenue * 0.9,
          },
        ],
      };
    }

    // Fallback to original sample data
    return (
      data.revenue || {
        current: 980000,
        previous: 850000,
        monthlyData: [
          { current: 75000, previous: 65000 },
          { current: 95000, previous: 75000 },
        ],
      }
    );
  };

  const revenueData = getCurrentData();

  // Calculate year-over-year growth
  const yearlyGrowth = (
    ((revenueData.current - revenueData.previous) / revenueData.previous) *
    100
  ).toFixed(1);

  // Get current and previous month data
  const currentMonth =
    revenueData.monthlyData[revenueData.monthlyData.length - 1];
  const previousMonth =
    revenueData.monthlyData[revenueData.monthlyData.length - 2];

  // Calculate month-over-month growth
  const monthlyGrowth = (
    ((currentMonth.current - previousMonth.current) / previousMonth.current) *
    100
  ).toFixed(1);

  const yearlyData = [
    { category: "Current Year", amount: revenueData.current },
    { category: "Previous Year", amount: revenueData.previous },
  ];

  const monthlyData = [
    { category: "Current Month", amount: currentMonth.current },
    { category: "Previous Month", amount: previousMonth.current },
  ];

  const legendSettings = {
    visible: true,
    position: "Bottom",
    textStyle: { size: "14px" },
  };

  const palette = ["#1890ff", "#c074e3"];

  return (
    <Card title="Revenue Comparison" className="mb-6">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <Card className="h-full">
            <Space direction="vertical" className="w-full">
              <Text strong className="text-lg">
                Yearly Comparison
                <span
                  style={{
                    marginLeft: "10px",
                    color: yearlyGrowth >= 0 ? "green" : "red",
                    fontSize: "0.8em",
                  }}
                >
                  {yearlyGrowth >= 0 ? "▲" : "▼"} {Math.abs(yearlyGrowth)}%
                </span>
              </Text>

              <AccumulationChartComponent
                id="yearly-revenue-pie"
                tooltip={{ enable: true }}
                legendSettings={legendSettings}
                height="300px"
              >
                <Inject
                  services={[
                    AccumulationLegend,
                    PieSeries,
                    AccumulationTooltip,
                    AccumulationDataLabel,
                  ]}
                />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective
                    dataSource={yearlyData}
                    xName="category"
                    yName="amount"
                    innerRadius="40%"
                    palettes={palette}
                    dataLabel={{
                      visible: true,
                      position: "Outside",
                      name: "category",
                      format: "${point.x}: ${point.y}",
                    }}
                  />
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            </Space>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="h-full">
            <Space direction="vertical" className="w-full">
              <Text strong className="text-lg">
                Monthly Comparison
                <span
                  style={{
                    marginLeft: "10px",
                    color: monthlyGrowth >= 0 ? "green" : "red",
                    fontSize: "0.8em",
                  }}
                >
                  {monthlyGrowth >= 0 ? "▲" : "▼"} {Math.abs(monthlyGrowth)}%
                </span>
              </Text>

              <AccumulationChartComponent
                id="monthly-revenue-pie"
                tooltip={{ enable: true }}
                legendSettings={legendSettings}
                height="300px"
              >
                <Inject
                  services={[
                    AccumulationLegend,
                    PieSeries,
                    AccumulationTooltip,
                    AccumulationDataLabel,
                  ]}
                />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective
                    dataSource={monthlyData}
                    xName="category"
                    yName="amount"
                    innerRadius="40%"
                    palettes={palette}
                    dataLabel={{
                      visible: true,
                      position: "Outside",
                      name: "category",
                      format: "${point.x}: ${point.y}",
                    }}
                  />
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            </Space>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default RevenueComparison;
