import React from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Space,
  Progress,
  Table,
  Badge,
  Tag,
} from "antd";
import {
  UserOutlined,
  IdcardOutlined,
  TeamOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationDataLabel,
  AccumulationTooltip,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  ColumnSeries,
  Tooltip,
} from "@syncfusion/ej2-react-charts";

const { Title, Text } = Typography;

// Dummy data for company dashboard
const employeeStats = {
  active: 850,
  inactive: 50,
  pendingIdCards: 30,
  totalIssued: 870,
};

const departmentData = [
  { department: "Engineering", count: 300 },
  { department: "Sales", count: 200 },
  { department: "Marketing", count: 150 },
  { department: "HR", count: 50 },
  { department: "Operations", count: 200 },
];

const recentActivities = [
  {
    key: 1,
    employee: "John Doe",
    action: "ID Card Issued",
    department: "Engineering",
    time: "2 hours ago",
  },
  {
    key: 2,
    employee: "Jane Smith",
    action: "ID Card Renewal",
    department: "Sales",
    time: "3 hours ago",
  },
  {
    key: 3,
    employee: "Mike Johnson",
    action: "New Employee",
    department: "Marketing",
    time: "5 hours ago",
  },
];

const pendingRequests = [
  {
    key: 1,
    employee: "Alice Brown",
    type: "New ID",
    department: "HR",
    status: "pending",
  },
  {
    key: 2,
    employee: "Bob Wilson",
    type: "Renewal",
    department: "Engineering",
    status: "processing",
  },
  {
    key: 3,
    employee: "Carol White",
    type: "New ID",
    department: "Sales",
    status: "pending",
  },
];

const monthlyStats = [
  { month: "Jan", issued: 45, renewed: 15 },
  { month: "Feb", issued: 38, renewed: 22 },
  { month: "Mar", issued: 50, renewed: 18 },
  { month: "Apr", issued: 42, renewed: 20 },
];

const CompanyDashboard = () => {
  const activePercentage = Math.round(
    (employeeStats.active / (employeeStats.active + employeeStats.inactive)) *
      100
  );

  const StatCard = ({ title, value, subtitle, icon: Icon, color }) => (
    <Card size="small" bodyStyle={{ padding: "15px" }}>
      <Space direction="horizontal" align="start" size="middle">
        <div
          style={{
            backgroundColor: `${color}20`,
            padding: "8px",
            borderRadius: "8px",
          }}
        >
          <Icon style={{ fontSize: 20, color: color }} />
        </div>
        <div>
          <Text type="secondary" style={{ fontSize: "12px" }}>
            {title}
          </Text>
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            {value.toLocaleString()}
          </div>
          {subtitle && (
            <Text type="secondary" style={{ fontSize: "11px" }}>
              {subtitle}
            </Text>
          )}
        </div>
      </Space>
    </Card>
  );

  const activityColumns = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text) => <Text type="secondary">{text}</Text>,
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <Row gutter={[16, 16]}>
        {/* Stats Cards */}
        <Col xs={24} sm={6}>
          <StatCard
            title="Total Employees"
            value={employeeStats.active + employeeStats.inactive}
            subtitle="Active & Inactive"
            icon={TeamOutlined}
            color="#1890ff"
          />
        </Col>
        <Col xs={24} sm={6}>
          <StatCard
            title="ID Cards Issued"
            value={employeeStats.totalIssued}
            subtitle="Total issued cards"
            icon={IdcardOutlined}
            color="#52c41a"
          />
        </Col>
        <Col xs={24} sm={6}>
          <StatCard
            title="Pending Requests"
            value={employeeStats.pendingIdCards}
            subtitle="Awaiting processing"
            icon={ClockCircleOutlined}
            color="#faad14"
          />
        </Col>
        <Col xs={24} sm={6}>
          <StatCard
            title="Active Employees"
            value={employeeStats.active}
            subtitle={`${activePercentage}% of total`}
            icon={UserOutlined}
            color="#13c2c2"
          />
        </Col>

        {/* Main Content */}
        <Col xs={24}>
          <Row gutter={[16, 16]}>
            {/* Department Distribution */}
            <Col xs={24} lg={12}>
              <Card
                size="small"
                title="Department Distribution"
                bodyStyle={{ height: "280px", padding: "8px" }}
              >
                <AccumulationChartComponent
                  id="department-pie"
                  tooltip={{ enable: true }}
                  legendSettings={{ visible: true, position: "Right" }}
                  height="100%"
                >
                  <Inject
                    services={[
                      AccumulationLegend,
                      PieSeries,
                      AccumulationDataLabel,
                      AccumulationTooltip,
                    ]}
                  />
                  <AccumulationSeriesCollectionDirective>
                    <AccumulationSeriesDirective
                      dataSource={departmentData}
                      xName="department"
                      yName="count"
                      innerRadius="40%"
                      dataLabel={{
                        visible: true,
                        position: "Inside",
                        name: "text",
                      }}
                    />
                  </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
              </Card>
            </Col>

            {/* ID Card Statistics */}
            <Col xs={24} lg={12}>
              <Card
                size="small"
                title="ID Card Statistics"
                bodyStyle={{ height: "280px", padding: "8px" }}
              >
                <ChartComponent
                  id="monthly-stats"
                  primaryXAxis={{ valueType: "Category" }}
                  height="100%"
                >
                  <Inject
                    services={[ColumnSeries, Category, Legend, Tooltip]}
                  />
                  <SeriesCollectionDirective>
                    <SeriesDirective
                      dataSource={monthlyStats}
                      xName="month"
                      yName="issued"
                      name="New Cards"
                      type="Column"
                    />
                    <SeriesDirective
                      dataSource={monthlyStats}
                      xName="month"
                      yName="renewed"
                      name="Renewals"
                      type="Column"
                    />
                  </SeriesCollectionDirective>
                </ChartComponent>
              </Card>
            </Col>

            {/* Recent Activities & Pending Requests */}
            <Col xs={24}>
              <Card
                size="small"
                title="Recent Activities"
                extra={<a href="#">View All</a>}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Table
                      dataSource={recentActivities}
                      columns={activityColumns}
                      pagination={false}
                      size="small"
                    />
                  </Col>
                  <Col span={12}>
                    <Title level={5}>Pending Requests</Title>
                    <Table
                      dataSource={pendingRequests}
                      columns={[
                        { title: "Employee", dataIndex: "employee" },
                        {
                          title: "Status",
                          dataIndex: "status",
                          render: (status) => (
                            <Badge
                              status={
                                status === "pending" ? "warning" : "processing"
                              }
                              text={status}
                            />
                          ),
                        },
                      ]}
                      pagination={false}
                      size="small"
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyDashboard;
