import React, { useState, useEffect } from "react";
import { Select, message } from "antd";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL, Countries } from "../Constants";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";

const SignupPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
    countryCode: "",
  });
  const [loading, setLoading] = useState(false);
  const [signupCompleted, setSignupCompleted] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [initialSignupData, setInitialSignupData] = useState(null);

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) {
      toast.error("Invalid signup link");
      return;
    }

    const validateToken = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/signup/validate-token`,
          JSON.stringify(token),
          {
            headers: {
              accept: "*/*",
              "Content-Type": "application/json",
            },
          }
        );
        setInitialSignupData(response.data);
        setFormData((prev) => ({
          ...prev,
          email: response.data.email,
        }));
      } catch (error) {
        toast.error(error.response?.data || "Invalid or expired signup link");
      }
    };

    validateToken();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCountryChange = (value) => {
    const selectedCountry = Countries.find(
      (country) => country.label === value
    );
    setFormData((prev) => ({
      ...prev,
      country: selectedCountry.label,
      countryCode: selectedCountry.code,
    }));
  };

  const validateForm = () => {
    const { firstName, lastName, companyName, country } = formData;

    if (!firstName) {
      toast.error("First name is required");
      return false;
    }
    if (!lastName) {
      toast.error("Last name is required");
      return false;
    }
    if (!country) {
      toast.error("Country is required");
      return false;
    }
    if (!companyName) {
      toast.error("Company name is required");
      return false;
    }

    return true;
  };

  const onFinish = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const completeSignupPayload = {
        ...initialSignupData,
        ...formData,
        isSignupCompleted: true,
      };

      await axios.put(
        `${BASE_URL}/signup/complete-signup`,
        completeSignupPayload
      );

      setSignupCompleted(true);
    } catch (error) {
      toast.error(error.response?.data || "Signup completion failed");
    } finally {
      setLoading(false);
    }
  };

  // Success Screen Component
  const SuccessScreen = () => (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 via-blue-100 to-blue-200 p-6">
      <div className="w-full max-w-md bg-white rounded-3xl shadow-2xl p-12 text-center">
        {/* <CheckCircleOutlined
          width={40}
          className="mx-auto mb-6 text-green-500"
        /> */}
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Registration Completed
        </h1>
        <p className="text-gray-600 mb-6">
          Your account has been successfully created.
        </p>

        <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
          <div className="flex items-center">
            <MailOutlined className="mr-3 text-blue-500" size={24} />
            <p className="text-blue-700 font-medium">Login Credentials Sent</p>
          </div>
          <p className="text-sm text-gray-600 mt-2">
            We've sent your login details to {formData.email}. Please check your
            email inbox.
          </p>
        </div>

        <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4 mb-6">
          <p className="text-yellow-700 font-medium mb-2">
            Important: Password Reset
          </p>
          <p className="text-sm text-gray-600">
            Please reset your password before logging in for the first time.
          </p>
        </div>

        <button
          onClick={() => navigate("/")}
          className="w-full flex justify-center items-center py-3 px-4 
              border border-transparent rounded-lg 
              shadow-md text-sm font-semibold text-white 
              bg-blue-600 hover:bg-blue-700 
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
              transition duration-300 ease-in-out 
              transform hover:scale-[1.02]"
        >
          Go to Login
          <ArrowRightOutlined className="ml-2" size={20} />
        </button>
      </div>
    </div>
  );

  // Render signup form or success screen
  if (signupCompleted) {
    return <SuccessScreen />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 via-blue-100 to-blue-200 p-6">
      <div className="w-full max-w-5xl bg-white/30 backdrop-blur-2xl rounded-3xl shadow-2xl overflow-hidden grid grid-cols-2">
        {/* Left Side - Digital ID Card Intro */}
        <div className="bg-gradient-to-br from-blue-600 to-indigo-700 text-white p-12 flex flex-col justify-center items-center">
          <img
            src="/logo512.png"
            alt="Future-Cards Logo"
            className="w-40 h-40 "
          />
          <h1 className="text-4xl font-bold mb-6 text-center">Future-Cards</h1>
          <p className="text-xl text-center mb-6">
            Revolutionizing Digital Identity Management
          </p>
          <div className="bg-white/20 p-6 rounded-xl text-center">
            <p className="text-sm">
              Create your secure digital identity profile. Streamline access,
              enhance security, and manage your professional credentials with
              ease.
            </p>
          </div>
        </div>

        {/* Right Side - Signup Form */}
        <div className="p-12 flex items-center justify-center">
          <div className="w-full max-w-md">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-8 text-center">
              Complete Your Registration
            </h2>

            <form onSubmit={onFinish} className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  disabled
                  className="block w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg 
                  focus:outline-none focus:ring-2 focus:ring-blue-500 
                  text-gray-600 cursor-not-allowed"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    First Name
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-lg 
                    focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-lg 
                    focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Company Name
                </label>
                <input
                  id="companyName"
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Company Name"
                  className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-lg 
                  focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Country
                </label>
                <Select
                  id="country"
                  style={{ width: "100%" }}
                  placeholder="Select your country"
                  onChange={handleCountryChange}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {Countries.map((country) => (
                    <Select.Option key={country.code} value={country.label}>
                      {country.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full flex justify-center py-3 px-4 
                  border border-transparent rounded-lg 
                  shadow-md text-sm font-semibold text-white 
                  bg-blue-600 hover:bg-blue-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                  transition duration-300 ease-in-out 
                  transform hover:scale-[1.02]
                  disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading
                    ? "Completing Registration..."
                    : "Complete Registration"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
