import React, { useContext, useState } from "react";

import AppContext from "../../AppContext";

const VirtualEmployeeCard = ({ employee, imagePreview }) => {
  const { cardConfig, companyLogo } = useContext(AppContext);
  const [isArabic, setIsArabic] = useState(false);

  const getTextStyle = (field) => {
    const textStyle = cardConfig.textStyles[field] || {};
    return {
      color: textStyle.color || "#000",
      fontSize: `${textStyle.size}px`,
      fontWeight: textStyle.bold ? "bold" : "normal",
      fontStyle: textStyle.italic ? "italic" : "normal",
    };
  };
  console.log(cardConfig, "config");
  const getLocalizedContent = (field) => {
    if (
      isArabic &&
      employee?.isArabicSupported &&
      employee?.arabicContent?.[field]
    ) {
      return employee.arabicContent[field];
    }
    return employee?.[field] || "";
  };
  return (
    <div className="flex flex-col items-end">
      {employee.isArabicSupported && (
        <div className="inline-flex rounded-lg mb-6 p-1 bg-white shadow-md">
          <button
            onClick={() => setIsArabic(false)}
            className={`px-6 py-2 rounded-md transition-colors ${
              !isArabic
                ? "bg-blue-500 text-white"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            EN
          </button>
          <button
            onClick={() => setIsArabic(true)}
            className={`px-6 py-2 rounded-md transition-colors ${
              isArabic
                ? "bg-blue-500 text-white"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            AR
          </button>
        </div>
      )}
      <div
        style={{
          backgroundColor: !cardConfig?.useBackgroundImage
            ? cardConfig?.backgroundColor
            : undefined,
          backgroundImage:
            cardConfig.useBackgroundImage && cardConfig.backgroundImageUrl
              ? `url(${cardConfig.backgroundImageUrl})`
              : undefined,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="bg-white rounded-lg w-96 shadow-lg overflow-hidden relative"
      >
        {/* Overlay div */}
        <div
          className="absolute inset-0 bg-black opacity-40"
          style={{
            backgroundColor: !cardConfig.useBackgroundImage
              ? cardConfig.backgroundColor
              : undefined,
          }}
        ></div>
        <div className="relative aspect-[2.4] w-full">
          {cardConfig.companyLogoVisible && (
            <div className="flex justify-center mt-14">
              <img
                src={companyLogo}
                alt="Company Logo"
                className="h-14 object-contain"
              />
            </div>
          )}
        </div>

        <div className="relative px-6 pb-6">
          {cardConfig.profileImageVisible && (
            <div className="absolute -top-16 left-1/2 -translate-x-1/2">
              <img
                src={
                  imagePreview ||
                  employee.profileImage ||
                  `./user-placeholder.png`
                }
                alt="Profile"
                className="w-32 h-32 rounded-full border-4 border-white object-cover"
              />
            </div>
          )}

          <div className="pt-20 text-center">
            {cardConfig.nameVisible && (
              <h3 style={getTextStyle("name")} className="mb-1">
                {`${getLocalizedContent("firstName")} ${getLocalizedContent(
                  "lastName"
                )}`}
              </h3>
            )}
            {cardConfig.designationVisible && (
              <p style={getTextStyle("designation")}>
                {getLocalizedContent("role")}
              </p>
            )}
          </div>

          <div className="mt-6 space-y-1 text-center">
            {cardConfig.phoneVisible && (
              <p style={getTextStyle("phone")}>{employee.phone}</p>
            )}
            {cardConfig.emailVisible && (
              <p style={getTextStyle("email")}>{employee.email}</p>
            )}
            {cardConfig.employeeIdVisible && (
              <p style={getTextStyle("employeeId")}>{employee.employeeID}</p>
            )}
          </div>

          <div
            className="mt-6 w-60 flex mx-auto border-t"
            style={{
              borderTop: `2px solid ${cardConfig.dividerColor}`,
            }}
          />

          <div className="pt-4 mb-4">
            {cardConfig.companyNameVisible && (
              <p style={getTextStyle("companyName")} className="text-center">
                {getLocalizedContent("companyName")}
              </p>
            )}
          </div>

          {cardConfig.qrCodeVisible && employee.digitalID?.qrCode && (
            <div className="flex justify-center">
              <img
                src={employee.digitalID.qrCode}
                alt="QR Code"
                width="100px"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VirtualEmployeeCard;
