import { GlobalOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher({ className }) {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
  };

  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
    if (i18n.language === "ar") {
      document.documentElement.classList.add("rtl");
    } else {
      document.documentElement.classList.remove("rtl");
    }
  }, [i18n.language]);

  return (
    <button
      className={`flex items-center px-4 py-2 bg-indigo-300 text-white rounded shadow hover:bg-indigo-400 focus:outline-none ${className}`}
      onClick={toggleLanguage}
    >
      <GlobalOutlined className="mr-2" />
      {i18n.language.toUpperCase()}
    </button>
  );
}

export default LanguageSwitcher;
