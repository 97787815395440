import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  SettingOutlined,
  MailOutlined,
  FormatPainterOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import axios from "axios";
import ThemeSettings from "./ThemeSettings";
import CustomizeDigitalCard from "./CustomizeDigitalCard";
import EmailTemplateEditor from "./EmailTemplateEditor";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next"; // Import useTranslation

function Settings() {
  const { t } = useTranslation(); // Initialize the translation function
  const [activeTab, setActiveTab] = useState("Theme");
  const [settingsData, setSettingsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { profile } = useContext(AppContext);
  const { id } = useParams();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://digital-id-api.azurewebsites.net/api/Company/settings?id=${id}`
        );
        setSettingsData(response.data);
        setError(null);
      } catch (err) {
        setError(t("messages.error.fetchCompany")); // Use translation for error message
        console.error("Error fetching settings:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [id, t]);

  const tabsData = [
    {
      id: "Theme",
      label: t("settings.tabs.theme"),
      icon: <FormatPainterOutlined className="text-lg" />,
      component: settingsData && (
        <ThemeSettings
          initialData={{
            primaryColor: settingsData.primaryColor,
            secondaryColor: settingsData.secondaryColor,
            primaryTextColor: settingsData.primaryTextColor,
            companyLogo: settingsData.iconUrl,
          }}
        />
      ),
    },
    {
      id: "CustomizeCard",
      label: t("settings.tabs.digitalCard"),
      icon: <IdcardOutlined className="text-lg" />,
      component: settingsData && (
        <CustomizeDigitalCard
          cardConfig={settingsData.digitalCardConfig}
          id={id}
          companyLogo={settingsData.iconUrl}
        />
      ),
    },
    profile.role === "super_admin" && {
      id: "CustomizeEmail",
      label: t("settings.tabs.emailTemplate"),
      icon: <MailOutlined className="text-lg" />,
      component: <EmailTemplateEditor />,
    },
  ].filter(Boolean);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 text-center">
          <p className="text-xl font-semibold">
            {t("settings.messages.settingsUpdateError")}
          </p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
          >
            {t("settings.messages.retry")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-full bg-transparent">
      <header className="py-6 bg-transparent">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white flex items-center gap-2">
            <SettingOutlined /> {t("settings.title")}
          </h1>
        </div>
      </header>

      <div className="bg-white rounded-lg shadow-lg mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Enhanced Tab Navigation */}
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8"
            aria-label="Settings navigation"
          >
            {tabsData.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm
                  ${
                    activeTab === tab.id
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }
                  transition-colors duration-200 ease-in-out
                  focus:outline-none focus:text-indigo-800 focus:border-indigo-700
                `}
              >
                <span
                  className={`
                  mr-2 
                  ${
                    activeTab === tab.id
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500"
                  }
                `}
                >
                  {tab.icon}
                </span>
                {tab.label}
              </button>
            ))}
          </nav>
        </div>

        {/* Tab Content */}
        <div className="py-6">
          {tabsData.find((tab) => tab.id === activeTab)?.component}
        </div>
      </div>
    </div>
  );
}

export default Settings;
