import React, { useState, useEffect, useContext } from "react";
import { Table, Layout, message } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

function Company() {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { profile } = useContext(AppContext);

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    const lowercasedFilter = searchText.toLowerCase();
    const filtered = companies.filter((company) => {
      return company.name.toLowerCase().includes(lowercasedFilter);
    });
    setFilteredData(filtered);
  }, [searchText, companies]);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Company?companyId=${
          profile.role !== "super_admin" ? profile.companyId : ""
        }`
      );
      setCompanies(response.data);
      setFilteredData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching companies:", error);
      message.error(t("messages.error.fetchCompany"));
      setLoading(false);
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(companies);
  };

  const columns = [
    {
      title: t("form.name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t("form.description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("form.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("form.phone"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("table.admin.actions"),
      key: "action",
      render: (_, record) => (
        <div className="space-x-2">
          <button
            type="button"
            onClick={() => navigate(`/company-form/${record.id}`)}
            className="bg-indigo-500 hover:bg-indigo-600 text-white px-3 py-2 rounded flex items-center"
          >
            <EditOutlined />
          </button>
        </div>
      ),
    },
  ];

  return (
    <Layout className="min-h-full bg-transparent">
      <header className="py-4 bg-transparent">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            {t("navigation.company")}
          </h1>
        </div>
      </header>
      <div className="rounded-lg px-5 py-6 shadow bg-white sm:px-6">
        <div className="">
          <div className="flex justify-between items-center mb-6">
            <div className="relative">
              <SearchOutlined className="absolute top-2 left-2 text-gray-400" />
              <input
                type="text"
                placeholder={t("placeholders.company.name")}
                value={searchText}
                style={{ width: 300, paddingLeft: "30px" }}
                onChange={(e) => setSearchText(e.target.value)}
                className="border border-gray-300 p-2 rounded"
              />
              {searchText && (
                <CloseCircleOutlined
                  className="absolute top-2 right-2 text-gray-400 cursor-pointer"
                  onClick={handleClearSearch}
                />
              )}
            </div>
            <div className="space-x-2">
              <button
                type="button"
                onClick={() => navigate("/company-form")}
                className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-1.5 rounded-lg flex items-center"
              >
                <PlusOutlined className="mr-2" />
                {t("buttons.new")}{" "}
                <span className="lg:inline-block hidden ml-1">
                  {t("navigation.company")}
                </span>
              </button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredData}
            rowKey="id"
            className="[&_.ant-table-thead_.ant-table-cell]:bg-gray-100 [&_.ant-table-thead_.ant-table-cell]:text-black"
            rowClassName={(record, index) =>
              `${
                index % 2 === 0 ? "bg-white" : "bg-gray-50"
              } hover:bg-gray-100 transition-colors`
            }
            loading={loading}
            scroll={{ x: true }}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showQuickJumper: true,
            }}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Company;
