import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import React from "react";

const InputWithVisibility = ({
  label,
  field,
  type = "text",
  value,
  onChange,
  cardData,
  setCardData,
}) => {
  // Memoize the visibility toggle handler
  const handleVisibilityToggle = React.useCallback(() => {
    setCardData((prev) => ({
      ...prev,
      [`${field}Visible`]: !prev[`${field}Visible`],
    }));
  }, [field, setCardData]);

  return (
    <div className="relative">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="flex gap-2">
        <input
          type={type}
          value={value}
          onChange={onChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="button"
          onClick={handleVisibilityToggle}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          {cardData[`${field}Visible`] ? (
            <EyeFilled className="w-5 h-5 text-gray-600" />
          ) : (
            <EyeInvisibleFilled className="w-5 h-5 text-gray-400" />
          )}
        </button>
      </div>
    </div>
  );
};

export default React.memo(InputWithVisibility);
