import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL, Countries } from "../../Constants";
import { v4 as uuidv4 } from "uuid";
import {
  CloseCircleOutlined,
  SaveOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { Button, Select } from "antd";
import OfficeFormModal from "./OfficeFormModal";
import DepartmentFormModal from "./DepartmentFormModal";
import AdminFormModal from "./AdminFormModal";
import TabNavigation from "./TabNavigation";
import SubscriptionManagementTab from "./SubscriptionManagement";
import { useTranslation } from "react-i18next";

const CompanyForm = () => {
  const { Option } = Select;
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    email: "",
    website: "",
    phone: "",
    countryCode: "",
    country: "",
    currency: "SAR",
    isActive: true,
    subscriptionId: "1",
    isSubscribed: false,
  });
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [activeTab, setActiveTab] = useState("details");
  const [companyList, setCompanyList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [offices, setOffices] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [modalType, setModalType] = useState("");
  const [editingItem, setEditingItem] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const validateRequiredFields = () => {
    const { name, country } = formValues;

    const isNameValid = name && name.trim() !== "";
    const isCountryValid = country && country.trim() !== "";
    // const isEmailValid = email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    return isNameValid && isCountryValid;
  };

  // const handleAdminSubmit = (values) => {
  //   const newAdmin = {
  //     ...values,
  //     id: editingItem ? editingItem.id : uuidv4(),
  //     role: "admin",
  //     isActive: true,
  //   };

  //   if (editingItem) {
  //     setAdmins(
  //       admins.map((admin) => (admin.id === editingItem.id ? newAdmin : admin))
  //     );
  //   } else {
  //     setAdmins([...admins, newAdmin]);
  //   }

  //   setIsModalVisible(false);
  //   setEditingItem(null);
  // };

  const handleAdminDelete = (id) => {
    setAdmins(admins.filter((admin) => admin.id !== id));
  };

  useEffect(() => {
    fetchCompanies();
    if (id) {
      fetchCompany(id);
    }
  }, [id]);

  const fetchCompany = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/Company/${id}`);
      setFormValues({
        ...response.data,
        isActive: response.data.isActive || true,
      });
      setDepartments(response.data.departments || []);
      setOffices(response.data.offices || []);
      setAdmins(response.data.admins || []);
      if (response.data.iconUrl) {
        setImagePreview(response.data.iconUrl);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching company:", error);
      toast.error("Failed to fetch company details");
      setLoading(false);
    }
  };
  const handleCountryChange = (value) => {
    const selectedCountry = Countries.find(
      (country) => country.label === value
    );
    setFormValues((prev) => ({
      ...prev,
      country: selectedCountry.label,
      countryCode: selectedCountry.code,
    }));
  };
  const onFinish = async (event, departments, offices) => {
    event?.preventDefault();
    setLoading(true);
    const formData = new FormData();

    Object.keys(formValues).forEach((key) => {
      if (
        key !== "departments" &&
        key !== "offices" &&
        key !== "admins" &&
        key !== "subscriptionDetails"
      ) {
        formData.append(`CompanyItem.${key}`, formValues[key]);
      }
    });

    if (id) {
      formData.append("CompanyItem.Id", id);
    }
    if (file) {
      formData.append("File", file);
    }
    departments.forEach((dept, index) => {
      Object.keys(dept).forEach((key) => {
        formData.append(`CompanyItem.departments[${index}].${key}`, dept[key]);
      });
    });

    offices.forEach((office, index) => {
      Object.keys(office).forEach((key) => {
        formData.append(`CompanyItem.offices[${index}].${key}`, office[key]);
      });
    });
    // admins.forEach((admin, index) => {
    //   Object.keys(admin).forEach((key) => {
    //     formData.append(`CompanyItem.admins[${index}].${key}`, admin[key]);
    //   });
    // });
    try {
      await axios({
        method: id ? "put" : "post",
        url: `${BASE_URL}/Company`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(`Company ${id ? "updated" : "created"} successfully`);
      navigate(`/company-form/${id}`);
    } catch (error) {
      console.error("Error saving company:", error);
      toast.error("Failed to save company");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setImagePreview(URL.createObjectURL(file));
      toast.success(`${file.name} file uploaded successfully`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleToggleChange = () => {
    setFormValues((prev) => ({ ...prev, isActive: !prev.isActive }));
  };

  const showModal = (type, item = null) => {
    setModalType(type);
    setEditingItem(item);
    setIsModalVisible(true);
  };
  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/Lookup/companies`);
      setCompanyList(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCompanyChange = (value, option) => {
    setFormValues((prev) => ({
      ...prev,
      parentCompanyId: option.value,
      parentCompanyName: option.children,
    }));
  };
  const handleModalOk = (values) => {
    let updatedOffices = offices;
    let updatedDepartments = departments;
    if (modalType === "department") {
      if (editingItem) {
        updatedDepartments = departments.map((dept) =>
          dept.id === editingItem.id ? { ...dept, ...values } : dept
        );
      } else {
        updatedDepartments = [...departments, { ...values, id: uuidv4() }];
      }
      setDepartments(updatedDepartments);
    } else if (modalType === "office") {
      if (editingItem) {
        updatedOffices = offices.map((office) =>
          office.id === editingItem.id ? { ...office, ...values } : office
        );
      } else {
        updatedOffices = [...offices, { ...values, id: uuidv4() }];
      }
      setOffices(updatedOffices);
    }
    setIsModalVisible(false);
    setEditingItem(null);
    onFinish(null, updatedDepartments, updatedOffices);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setEditingItem(null);
  };

  const handleDelete = (type, id) => {
    if (type === "department") {
      setDepartments(departments.filter((dept) => dept.id !== id));
    } else if (type === "office") {
      setOffices(offices.filter((office) => office.id !== id));
    }
  };

  // const isFormValid = Object.values(formValues).every((value) => value) || id;
  const activateSubscription = async () => {
    try {
      await axios.post(
        `${BASE_URL}/Subscription/activate?companyId=${formValues.id}&subscriptionId=${formValues.subscriptionId}`
      );
      setFormValues((prev) => ({
        ...prev,
        isSubscribed: true,
      }));
      toast.success("Subscription activated successfully");
    } catch (error) {
      console.error("Error activating subscription:", error);
      toast.error("Failed to activate subscription");
    }
  };

  const deactivateSubscription = async () => {
    try {
      await axios.post(
        `${BASE_URL}/Subscription/deactivate?companyId=${formValues.id}&subscriptionId=${formValues.subscriptionId}`
      );
      setFormValues((prev) => ({
        ...prev,
        isSubscribed: false,
      }));

      toast.success("Subscription deactivated successfully");
    } catch (error) {
      console.error("Error deactivating subscription:", error);
      toast.error("Failed to deactivate subscription");
    }
  };

  const toggleSubscription = () => {
    if (formValues.isSubscribed) {
      deactivateSubscription();
    } else {
      activateSubscription();
    }
  };
  return (
    <div className="min-h-full bg-transparent p-6">
      <header className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            {id ? t("pageTitle.editCompany") : t("pageTitle.newCompany")}
          </h1>
        </div>
      </header>
      <div className="rounded-lg px-5 py-6 shadow bg-white sm:px-6">
        {/* Tabs */}
        <TabNavigation
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          id={id}
        />

        {activeTab === "details" && (
          <form onSubmit={(e) => onFinish(e, departments, offices)}>
            <div className="grid grid-cols-2 gap-x-2">
              <div className="mb-3">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("form.name")} <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  placeholder={t("placeholders.company.name")}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                />
              </div>
              <div className="">
                <label className="block text-sm font-medium text-gray-700">
                  {t("form.parentCompany")}
                </label>
                <Select
                  showSearch
                  value={
                    formValues.parentCompanyName === "null"
                      ? null
                      : formValues.parentCompanyName
                  }
                  placeholder={t("placeholders.company.parentCompany")}
                  onChange={handleCompanyChange}
                  className="mt-2 block w-full"
                  optionFilterProp="children"
                >
                  {companyList.map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="mb-3">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("form.arabicName")}
                </label>
                <input
                  type="text"
                  name="arabicName"
                  value={
                    formValues.arabicName === "null"
                      ? undefined
                      : formValues.arabicName
                  }
                  onChange={handleChange}
                  placeholder={t("placeholders.company.arabicName")}
                  className="w-full px-3 py-2 border text-right rounded-lg"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("form.description")}
              </label>
              <textarea
                name="description"
                value={formValues.description}
                onChange={handleChange}
                placeholder={t("placeholders.description")}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>

            {/* Email, Website, Phone, Country section */}
            <div className="grid grid-cols-2 gap-x-2">
              <div className="mb-3">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("form.email")} <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  placeholder={t("placeholders.email")}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>

              <div className="mb-3">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("form.website")}
                </label>
                <input
                  type="url"
                  name="website"
                  value={formValues.website}
                  onChange={handleChange}
                  placeholder={t("placeholders.website")}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>

              <div className="mb-3">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("form.phone")}
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formValues.phone}
                  onChange={handleChange}
                  placeholder={t("placeholders.phone")}
                  className="w-full px-3 py-2 border"
                  pattern="^[0-9]*$"
                  required
                />
              </div>

              <div className="mb-3">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("form.country")} <span className="text-red-500">*</span>
                </label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={t("placeholders.country")}
                  optionFilterProp="children"
                  onChange={handleCountryChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={formValues.country || undefined}
                >
                  {Countries.map((country) => (
                    <Option key={country.code} value={country.label}>
                      {country.label}
                    </Option>
                  ))}
                </Select>
              </div>

              {/* Logo upload section */}
              <div className="mb-3">
                <label className="block text-gray-600 font-medium mb-2">
                  {t("form.logo")}
                </label>
                <div className="flex items-center">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleUploadChange}
                    className="hidden"
                    id="fileUpload"
                  />
                  <label
                    htmlFor="fileUpload"
                    className="bg-indigo-500 text-white px-4 py-2 rounded-lg cursor-pointer hover:bg-indigo-600"
                  >
                    {t("buttons.chooseFile")}
                  </label>
                  {imagePreview && (
                    <div className="ml-4 flex items-center">
                      <img
                        src={imagePreview}
                        alt="Company Logo"
                        className="w-12 h-12 object-cover rounded-lg border ml-3"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setImagePreview(null);
                          setFile(null);
                        }}
                        className="ml-2 text-red-500 hover:text-red-700"
                      >
                        {t("buttons.remove")}
                      </button>
                    </div>
                  )}
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  {t("fileUpload.supportedFormats")}{" "}
                  <span className="font-medium">
                    {t("fileUpload.formatTypes")}
                  </span>
                </p>
              </div>

              {/* Active toggle */}
              <div className="mb-4 flex items-center">
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={formValues.isActive}
                    onChange={handleToggleChange}
                    className="checkbox"
                  />
                  <label className="switch" htmlFor="checkbox">
                    <span className="slider"></span>
                  </label>
                </div>
                <label className="mr-2">{t("form.active")}</label>
              </div>
            </div>

            {/* Action buttons */}
            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="flex items-center gap-2 px-3 py-1 bg-red-600 text-white rounded-lg disabled:opacity-50"
                disabled={loading}
              >
                <CloseCircleOutlined />
                <span>{t("buttons.cancel")}</span>
              </button>
              <button
                type="submit"
                className={`flex items-center gap-2 px-3 py-1 ${
                  validateRequiredFields() ? "bg-indigo-600" : "bg-indigo-300"
                } text-white rounded-lg disabled:opacity-50`}
                disabled={!validateRequiredFields() || loading}
              >
                {loading ? (
                  <span className="animate-pulse">{t("buttons.saving")}</span>
                ) : (
                  <>
                    <SaveOutlined />
                    <span>{t("buttons.save")}</span>
                  </>
                )}
              </button>
            </div>
          </form>
        )}

        {/* Departments Tab */}
        {activeTab === "departments" && (
          <div>
            <div className="flex justify-between mb-4">
              <h2 className="text-xl font-bold">{t("tabs.departments")}</h2>
              <button
                type="button"
                onClick={() => showModal("department")}
                className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-1 rounded-lg flex items-center"
              >
                <PlusOutlined className="mr-2" />
                {t("buttons.new")}{" "}
                <span className="lg:inline-block hidden ml-1">
                  {t("tabs.departments")}
                </span>
              </button>
            </div>

            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    {Object.keys(
                      t("table.department", { returnObjects: true })
                    ).map((key) => (
                      <th
                        key={key}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        {t(`table.department.${key}`)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {departments.map((dept) => (
                    <tr key={dept.id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {dept.name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {dept.description}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {dept?.arabicName}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => showModal("department", dept)}
                          className="text-indigo-600 hover:text-indigo-900 mr-3"
                        />
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => handleDelete("department", dept.id)}
                          danger
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {activeTab === "offices" && (
          <div>
            <div className="flex justify-between mb-4">
              <h2 className="text-xl font-bold">{t("tabs.offices")}</h2>
              <button
                type="button"
                onClick={() => showModal("office")}
                className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-1 h-fit rounded-lg flex items-center"
              >
                <PlusOutlined className="mr-2" />
                {t("buttons.new")}{" "}
                <span className="lg:inline-block hidden ml-1">
                  {t("tabs.offices")}
                </span>
              </button>
            </div>
            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    {Object.keys(
                      t("table.office", { returnObjects: true })
                    ).map((key) => (
                      <th
                        key={key}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        {t(`table.office.${key}`)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {offices.map((office) => (
                    <tr key={office.id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {office.name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 max-w-56 truncate whitespace-no-wrap">
                          {office.address}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {office.city}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => showModal("office", office)}
                          className="text-indigo-600 hover:text-indigo-900 mr-3"
                        />
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => handleDelete("office", office.id)}
                          danger
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Admins Tab */}
        {activeTab === "admins" && (
          <div>
            <div className="flex justify-between mb-4">
              <h2 className="text-xl font-bold">{t("tabs.admins")}</h2>
              <button
                type="button"
                onClick={() => showModal("admin")}
                className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-1 rounded-lg flex items-center"
              >
                <PlusOutlined className="mr-2" />
                {t("buttons.new")}{" "}
                <span className="lg:inline-block hidden ml-1">
                  {t("tabs.admins")}
                </span>
              </button>
            </div>

            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    {Object.keys(t("table.admin", { returnObjects: true })).map(
                      (key) => (
                        <th
                          key={key}
                          className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          {t(`table.admin.${key}`)}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {admins.map((admin) => (
                    <tr key={admin.id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {`${admin.firstName} ${admin.lastName}`}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {admin.email}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {admin.username}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => showModal("admin", admin)}
                          className="text-indigo-600 hover:text-indigo-900 mr-3"
                        />
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => handleAdminDelete(admin.id)}
                          danger
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Subscription Tab */}
        {activeTab === "subscription" && (
          <SubscriptionManagementTab
            formValues={formValues}
            onActivateTrial={async () => {
              try {
                await axios.post(
                  `${BASE_URL}/Subscription/activate-trial?companyId=${formValues.id}&subscriptionId=${formValues.subscriptionId}`
                );
                await fetchCompany(formValues.id);
                toast.success(t("messages.success.trialActivated"));
              } catch (error) {
                console.error("Error activating trial:", error);
                toast.error(t("messages.error.trialActivation"));
              }
            }}
            onActivateSubscription={async (durationType) => {
              try {
                await axios.post(
                  `${BASE_URL}/Subscription/activate?companyId=${formValues.id}&subscriptionId=${formValues.subscriptionId}&durationType=${durationType}`
                );
                await fetchCompany(formValues.id);
                toast.success(t("messages.success.subscriptionActivated"));
              } catch (error) {
                console.error("Error activating subscription:", error);
                toast.error(t("messages.error.subscriptionActivation"));
              }
            }}
            onDeactivateSubscription={async () => {
              try {
                await axios.post(
                  `${BASE_URL}/Subscription/deactivate?companyId=${formValues.id}&subscriptionId=${formValues.subscriptionId}`
                );
                await fetchCompany(formValues.id);
                toast.success(t("messages.success.subscriptionDeactivated"));
              } catch (error) {
                console.error("Error deactivating subscription:", error);
                toast.error(t("messages.error.subscriptionDeactivation"));
              }
            }}
          />
        )}
      </div>
      {/* Modal for Department Form */}
      {isModalVisible && modalType === "department" && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="relative w-full max-w-lg">
            <DepartmentFormModal
              initialValues={editingItem}
              onSubmit={(values) => {
                const departmentData = {
                  ...values,
                  id: editingItem ? editingItem.id : Date.now(),
                };
                handleModalOk(departmentData);
              }}
              onCancel={handleModalCancel}
            />
          </div>
        </div>
      )}

      {/* Modal for Office Form */}
      {isModalVisible && modalType === "office" && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="relative w-full max-w-2xl">
            <OfficeFormModal
              initialValues={editingItem}
              onSubmit={(values) => {
                const officeData = {
                  ...values,
                  id: editingItem ? editingItem.id : Date.now(),
                };
                handleModalOk(officeData);
              }}
              onCancel={handleModalCancel}
            />
          </div>
        </div>
      )}

      {/* Modal for Admin Form */}
      {isModalVisible && modalType === "admin" && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="relative w-full max-w-lg">
            <AdminFormModal
              initialValues={editingItem}
              onCancel={handleModalCancel}
              companyId={formValues.id}
              companyName={formValues.name}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyForm;
